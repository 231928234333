import {
    DatafeedConfiguration,
    LibrarySymbolInfo,
    Bar,
    Mark,
    TimescaleMark,
    QuoteData,
    SearchSymbolResultItem,
} from "charting_library";
import IReplayDatafeedAPI from "./replay_datafeed_api_base";
import axios, { AxiosInstance } from "axios";
import {
    ReplayBar,
    ReplayDatafeedConfiguration,
    ReplayDatafeedSymbolInfo,
    ReplaySearchSymbolResultItem,
} from "../models/replay_datafeed_models";
import { signWithToken } from "../../../utils/crypto";

export default class ReplayDatafeedAPI implements IReplayDatafeedAPI {
    protected instance: AxiosInstance;

    constructor(domain: string, port: string) {
        this.instance = axios.create({
            baseURL: `${domain}:${port}/api/datafeed`,
        });
    }

    async config(): Promise<DatafeedConfiguration> {
        const { userId, signature, payload } = await signWithToken();
        const response = await this.instance.get("/config", {
            headers: {
                "X-USER-ID": userId,
                "X-REQUEST-SIGNATURE": signature,
                "X-REQUEST-PAYLOAD": payload,
            },
        });
        console.log(response.data);

        const c = Object.assign(new ReplayDatafeedConfiguration(), response.data);

        console.log("my config", c);

        return c;
    }

    async symbolInfo(group: string): Promise<LibrarySymbolInfo[]> {
        throw new Error("Method not implemented.");
    }

    async symbols(symbol: string): Promise<LibrarySymbolInfo> {
        const { userId, signature, payload } = await signWithToken();
        const response = await this.instance.get("/symbols", {
            params: {
                symbol: symbol,
            },
            headers: {
                "X-USER-ID": userId,
                "X-REQUEST-SIGNATURE": signature,
                "X-REQUEST-PAYLOAD": payload,
            },
        });

        const s = Object.assign(
            new ReplayDatafeedSymbolInfo(
                "",
                "",
                "",
                "",
                "",
                "",
                "Etc/UTC",
                "price",
                0,
                0
            ),
            response.data
        );

        console.log("my symbol resolve", s);

        return s;
    }

    async search(
        query: string,
        type: string,
        exchange: string,
        limit: number
    ): Promise<SearchSymbolResultItem[]> {
        const { userId, signature, payload } = await signWithToken();
        const response = await this.instance.get("/search", {
            params: {
                query: query.toUpperCase(),
                type: type,
                exchange: exchange,
                limit: limit,
            },
            headers: {
                "X-USER-ID": userId,
                "X-REQUEST-SIGNATURE": signature,
                "X-REQUEST-PAYLOAD": payload,
            },
        });

        return response.data.map((s: any) =>
            Object.assign(new ReplaySearchSymbolResultItem("", "", "", "", ""), s)
        );
    }

    async history(
        symbol: string,
        from: string,
        to: string,
        resolution: string,
        countback: number
    ): Promise<[Bar[], boolean?]> {
        const { userId, signature, payload } = await signWithToken();
        const response = await this.instance.get("/history", {
            params: {
                symbol: symbol,
                from: from,
                to: to,
                resolution: resolution,
                countback: countback,
            },
            headers: {
                "X-USER-ID": userId,
                "X-REQUEST-SIGNATURE": signature,
                "X-REQUEST-PAYLOAD": payload,
            },
        });

        console.log("received history response");
        console.log(response.data);

        if (response.data.s == "no_data") {
            return [[], true];
        }

        let rawBars = response.data;
        const bars: Bar[] = [];

        for (let i = 0; i < rawBars.t.length; i++) {
            const time = rawBars.t[i] * 1000;
            const open = parseFloat(rawBars.o[i]);
            const high = parseFloat(rawBars.h[i]);
            const close = parseFloat(rawBars.c[i]);
            const low = parseFloat(rawBars.l[i]);
            const volume = parseFloat(rawBars.v[i]);

            const bar = new ReplayBar(time, open, high, low, close, volume);

            bars.push(bar);
        }

        return [bars];
    }

    async marks(
        symbol: string,
        from: string,
        to: string,
        resolution: string
    ): Promise<Mark[]> {
        throw new Error("Method not implemented.");
    }

    async timeScaleMarks(
        symbol: string,
        from: string,
        to: string,
        resolution: string
    ): Promise<TimescaleMark[]> {
        throw new Error("Method not implemented.");
    }

    async time(): Promise<string> {
        const { userId, signature, payload } = await signWithToken();
        const response = await this.instance.get("/time", {
            headers: {
                "X-USER-ID": userId,
                "X-REQUEST-SIGNATURE": signature,
                "X-REQUEST-PAYLOAD": payload,
            },
        });
        return response.data;
    }

    async quotes(symbols: string[]): Promise<QuoteData[]> {
        throw new Error("Method not implemented.");
    }
}
