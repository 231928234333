import { SetterOrUpdater } from "recoil";
import IBacktestingAPIBase from "../api/backtesting_api_base";
import { Rule, RuleGroup, Session, Strategy, Trade, Order, BasicSymbolInfo } from "../models/backtesting_models";
import IBactestingManagerBase from "./backtesting_manager_base";

export default class BacktestingManager<T extends IBacktestingAPIBase> implements IBactestingManagerBase {
    constructor(protected readonly api: T) { }

    async createSession(setSessionState: SetterOrUpdater<Session[]>, name: string, accountBalance: number, pairs: string[], startDate: number, endDate: number, description?: string, strategyId?: string): Promise<void> {
        const session = await this.api.createSession(name, accountBalance, pairs, startDate, endDate, description, strategyId);

        setSessionState((sessions) => [session, ...sessions]);
    }

    async listSessions(setSessionState: SetterOrUpdater<Session[]>, sessionName?: string, strategyId?: string): Promise<void> {
        const sessions = await this.api.listSessions(sessionName, strategyId);

        setSessionState(() => [...sessions]);
    }

    async getSession(setSessionState: SetterOrUpdater<Session[]>, sessionId: string): Promise<Session> {
        const session = await this.api.getSession(sessionId);

        setSessionState((sessions) => [session, ...sessions]);

        return session;
    }

    async topupSessionBalance(setSessionState: SetterOrUpdater<Session[]>, sessionId: string, amountToAdd: number): Promise<void> {
        throw new Error("Method not implemented.");
    }

    async updateSessionResolution(setSessionState: SetterOrUpdater<Session[]>, sessionId: string, resolution: string): Promise<void> {
        await this.api.updateSessionResolution(sessionId, resolution);

        setSessionState((sessions) => sessions.map((session) => {
            if (session.id == sessionId) {
                session.resolution = resolution;
            }

            return session;
        }));
    }

    async updateSessionStopTime(setSessionState: SetterOrUpdater<Session[]>, sessionId: string, stopTime: number): Promise<void> {
        await this.api.updateSessionStopTime(sessionId, stopTime);

        setSessionState((sessions) => sessions.map((session) => {
            if (session.id == sessionId) {
                session.stopDate = stopTime;
            }

            return session;
        }));
    }

    async updateSession(setSessionState: SetterOrUpdater<Session[]>, sessionId: string, name?: string, description?: string, strategyId?: string, endDate?: number): Promise<void> {
        await this.api.updateSession(sessionId, name, description, strategyId, endDate);

        setSessionState((sessions) => sessions.map((session) => {
            if (session.id == sessionId) {
                if (name != undefined) session.name = name;
                if (description != undefined) session.description = description;
                if (strategyId != undefined) session.strategyId = strategyId;
                if (endDate != undefined) session.endDate = endDate;

                return session;
            }

            return session;
        }));
    }

    async deleteSession(setSessionState: SetterOrUpdater<Session[]>, sessionId: string): Promise<void> {
        await this.api.deleteSession(sessionId);

        setSessionState((sessions => sessions.filter((session) => session.id != sessionId)));
    }

    async createStrategy(setStrategiesState: SetterOrUpdater<Strategy[]>, name: string, description: string): Promise<void> {
        const strategy = await this.api.createStrategy(name, description);

        setStrategiesState((strategies) => [strategy, ...strategies]);
    }

    async listStrategies(setStrategiesState: SetterOrUpdater<Strategy[]>, strategyName?: string): Promise<void> {
        const strategies = await this.api.listStrategies(strategyName);

        setStrategiesState(() => [...strategies]);
    }

    async getStrategy(setStrategiesState: SetterOrUpdater<Strategy[]>, strategyId: string): Promise<Strategy> {
        const strategy = await this.api.getStrategy(strategyId);

        setStrategiesState((strategies) => [strategy, ...strategies]);

        return strategy;
    }

    async updateStrategy(setStrategiesState: SetterOrUpdater<Strategy[]>, strategyId: string, strategyName?: string, strategyDescription?: string): Promise<void> {
        await this.api.updateStrategy(strategyId, strategyName, strategyDescription);

        setStrategiesState((strategies) => strategies.map((strategy) => {
            if (strategy.id == strategyId) {
                if (strategyName != undefined) strategy.name = strategyName;
                if (strategyDescription != undefined) strategy.description = strategyDescription;

                return strategy;
            }

            return strategy;
        }));
    }

    async deleteStrategy(setStrategiesState: SetterOrUpdater<Strategy[]>, strategyId: string): Promise<void> {
        await this.api.deleteStrategy(strategyId);

        setStrategiesState((strategies) => strategies.filter((strategy) => strategy.id != strategyId));
    }

    async createRuleGroup(setRuleGroupState: SetterOrUpdater<RuleGroup[]>, name: string, description: string, strategyId: string): Promise<void> {
        const ruleGroup = await this.api.createRuleGroup(name, description, strategyId);

        setRuleGroupState((groups) => [ruleGroup, ...groups]);
    }

    async listRuleGroups(setRuleGroupState: SetterOrUpdater<RuleGroup[]>, strategyId: string): Promise<void> {
        const ruleGroups = await this.api.listRuleGroups(strategyId);

        setRuleGroupState(ruleGroups);
    }

    async getRuleGroup(setRuleGroupState: SetterOrUpdater<RuleGroup[]>, groupId: string): Promise<RuleGroup> {
        const ruleGroup = await this.api.getRuleGroup(groupId);

        setRuleGroupState((groups) => [ruleGroup, ...groups]);

        return ruleGroup;
    }

    async updateRuleGroup(setRuleGroupState: SetterOrUpdater<RuleGroup[]>, groupId: string, groupName?: string, groupDescription?: string): Promise<void> {
        await this.api.updateRuleGroup(groupId, groupName, groupDescription);

        setRuleGroupState((groups) => groups.map((group) => {
            if (group.id == groupId) {
                if (groupName != undefined) group.name = groupName;
                if (groupDescription != undefined) group.description = groupDescription;
            }

            return group;
        }))
    }

    async deleteRuleGroup(setRuleGroupState: SetterOrUpdater<RuleGroup[]>, groupId: string): Promise<void> {
        await this.api.deleteRuleGroup(groupId);

        setRuleGroupState((groups) => groups.filter((group) => group.id != groupId));
    }

    async createRule(setRuleState: SetterOrUpdater<Rule[]>, strategyId: string, groupId: string, name: string, description: string): Promise<void> {
        const rule = await this.api.createRule(strategyId, groupId, name, description);

        setRuleState((rules) => [rule, ...rules]);
    }

    async listRules(setRuleState: SetterOrUpdater<Rule[]>, groupId: string): Promise<void> {
        const rules = await this.api.listRules(groupId);

        setRuleState((previousRules) => [...previousRules, ...rules]);
    }

    async getRule(setRuleState: SetterOrUpdater<Rule[]>, ruleId: string): Promise<Rule> {
        const rule = await this.api.getRule(ruleId);

        setRuleState((rules) => [rule, ...rules]);

        return rule;
    }

    async updateRule(setRuleState: SetterOrUpdater<Rule[]>, ruleId: string, ruleName?: string, ruleDescription?: string): Promise<void> {
        await this.api.updateRule(ruleId, ruleName, ruleDescription);

        setRuleState((rules) => rules.map((rule) => {
            if (rule.id == ruleId) {
                if (ruleName != undefined) rule.name = ruleName;
                if (ruleDescription != undefined) rule.description = ruleDescription;
            }

            return rule;
        }))
    }

    async deleteRule(setRuleState: SetterOrUpdater<Rule[]>, ruleId: string): Promise<void> {
        await this.api.deleteRule(ruleId);

        setRuleState((rules) => rules.filter((rule) => rule.id != ruleId));
    }

    async listTrades(setTradeState: SetterOrUpdater<Trade[]>): Promise<void> {
        const trades = await this.api.listTrades();

        setTradeState(trades);
    }

    async listSessionTrades(setTradeState: SetterOrUpdater<Trade[]>, sessionId: string): Promise<void> {
        const trades = await this.api.listSessionTrades(sessionId);

        setTradeState(trades);
    }

    async listStrategyTrades(setTradeState: SetterOrUpdater<Trade[]>, strategyId: string): Promise<void> {
        const trades = await this.api.listStrategyTrades(strategyId);

        setTradeState(trades);
    }

    async getTrade(setTradeState: SetterOrUpdater<Trade[]>, tradeId: string): Promise<Trade> {
        const trade = await this.api.getTrade(tradeId);

        setTradeState((trades) => [trade, ...trades]);

        return trade;
    }

    async updateTrade(setTradeState: SetterOrUpdater<Trade[]>, tradeId: string, profitTarget?: number, stopLoss?: number, setups?: string[], mistakes?: string[], custom?: string[], rulesFollowed?: string[]): Promise<void> {
        await this.api.updateTrade(tradeId, profitTarget, stopLoss, setups, mistakes, custom, rulesFollowed);

        setTradeState((trades) => trades.map((trade) => {
            if (trade.id == tradeId) {
                if (profitTarget != undefined) trade.profitTarget = profitTarget;
                if (stopLoss != undefined) trade.stopLoss = stopLoss;
                if (setups != undefined) trade.setups = setups;
                if (mistakes != undefined) trade.mistakes = mistakes;
                if (custom != undefined) trade.custom = custom;
                if (rulesFollowed != undefined) trade.rulesFollowed = rulesFollowed;
            }

            return trade;
        }));
    }

    async closeTrade(setTradeState: SetterOrUpdater<Trade[]>, tradeId: string, closePrice: number, closeTime: number): Promise<void> {
        await this.api.closeTrade(tradeId, closePrice, closeTime);

        setTradeState((trades) => trades.map((trade) => {
            if (trade.id == tradeId) {
                trade.status = "closed";
            }

            return trade;
        }));
    }

    async takeOrder(setOrderState: SetterOrUpdater<Order[]>, sessionId: string, side: string, orderType: string, price: number, amount: number, createdSessionTime: number, product: string, tags: string[], stopLoss?: number, takeProfit?: number, strategyId?: string): Promise<void> {
        const order = await this.api.takeOrder(sessionId, side, orderType, price, amount, createdSessionTime, product, tags, stopLoss, takeProfit, strategyId);

        setOrderState((orders) => [order, ...orders]);
    }

    async triggerOrder(setOrderState: SetterOrUpdater<Order[]>, sessionId: string, orderId: string, triggerTime: number): Promise<void> {
        const triggeredOrder = await this.api.triggerOrder(sessionId, orderId, triggerTime);

        setOrderState((orders) => orders.map((order) => {
            if (order.id == orderId) {
                return triggeredOrder;
            }

            return order;
        }));
    }

    async listOrders(setOrderState: SetterOrUpdater<Order[]>, sessionId: string): Promise<void> {
        const orders = await this.api.listOrders(sessionId);

        setOrderState(orders);
    }

    async listTradeOrders(setOrderState: SetterOrUpdater<Order[]>, sessionId: string, tradeId: string): Promise<void> {
        const orders = await this.api.listTradeOrders(sessionId, tradeId);

        setOrderState(orders);
    }

    async listPassedOrders(setOrderState: SetterOrUpdater<Order[]>, sessionId: string): Promise<void> {
        const orders = await this.api.listPassedOrders(sessionId);

        setOrderState(orders);
    }

    async listAwaitingOrders(setOrderState: SetterOrUpdater<Order[]>, sessionId: string): Promise<void> {
        const orders = await this.api.listAwaitingOrders(sessionId);

        setOrderState(orders);
    }

    async getOrder(setOrderState: SetterOrUpdater<Order[]>, orderId: string): Promise<Order> {
        const order = await this.api.getOrder(orderId);

        setOrderState((orders) => [order, ...orders]);

        return order;
    }

    async updateOrder(setOrderState: SetterOrUpdater<Order[]>, orderId: string, side?: string, entry?: number, stopLoss?: number, takeProfit?: number, tags?: string[]): Promise<void> {
        await this.api.updateOrder(orderId, side, entry, stopLoss, takeProfit, tags);

        setOrderState((orders) => orders.map((order) => {
            if (order.id == orderId) {
                if (side != undefined) order.side = side;
                if (entry != undefined) order.price = entry;
                if (stopLoss != undefined) order.stopLoss = stopLoss;
                if (takeProfit != undefined) order.takeProfit = takeProfit;
                if (tags != undefined) order.tags = tags;
            }

            return order;
        }));
    }

    async cancelOrder(setOrderState: SetterOrUpdater<Order[]>, orderId: string): Promise<void> {
        await this.api.cancelOrder(orderId);

        setOrderState((orders) => orders.map((order) => {
            if (order.id == orderId) {
                order.state = "canceled";
            }

            return order;
        }));
    }

    async deleteOrder(setOrderState: SetterOrUpdater<Order[]>, orderId: string): Promise<void> {
        await this.api.deleteOrder(orderId);

        setOrderState((orders) => orders.filter((order) => order.id != orderId));
    }

    async listAllPairs(setSymbolsState: SetterOrUpdater<BasicSymbolInfo[]>): Promise<void> {
        const symbols = await this.api.listAllSymbols();

        setSymbolsState(symbols);
    }
}