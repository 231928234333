import { useEffect, useState } from "react";

export default function useSize() {
    const [size, setSize] = useState([
        window.innerHeight,
        window.innerWidth
    ]);

    useEffect(() => {
        const windowSizeHandler = () => {

            setSize([window.innerHeight, window.innerWidth]);
        };
        window.addEventListener("resize", windowSizeHandler);

        return () => {
            window.removeEventListener("resize", windowSizeHandler);
        };
    }, []);

    return size;
}