import { signWithToken } from "../../../utils/crypto";
import { BasicSymbolInfo, Order, Rule, RuleGroup, Session, Strategy, Trade } from "../models/backtesting_models";
import IBacktestingAPIBase from "./backtesting_api_base";
import axios, { AxiosInstance } from "axios";

export default class BacktestingAPI implements IBacktestingAPIBase {
    protected instance: AxiosInstance;
    protected candleDataInstance: AxiosInstance;

    constructor(domain: string, port: string) {
        this.instance = axios.create({
            baseURL: `${domain}:${port}/api/backtesting`,
        });

        this.candleDataInstance = axios.create({
            baseURL: `${domain}:${port}/api/candle_data`,
        });
    }


    async createSession(
        name: string,
        accountBalance: number,
        pairs: string[],
        startDate: number,
        endDate: number,
        description?: string,
        strategyId?: string
    ): Promise<Session> {
        const { userId, signature, payload } = await signWithToken();
        const res = await this.instance.post(
            "/session",
            {
                name,
                accountBalance,
                pairs,
                startDate,
                endDate,
                description,
                strategyId,
            },
            {
                headers: {
                    "X-USER-ID": userId,
                    "X-REQUEST-SIGNATURE": signature,
                    "X-REQUEST-PAYLOAD": payload,
                },
            }
        );

        const session: Session = Object.assign(
            new Session("", "", 0, [], "", 0, 0, 0, "", "", 0, "", 0),
            res.data
        );

        return session;
    }

    async listSessions(
        sessionName?: string,
        strategyId?: string
    ): Promise<Session[]> {
        const { userId, signature, payload } = await signWithToken();
        const res = await this.instance.get("/sessions", {
            params: {
                sessionName,
                strategyId,
                userId,
                lastIndex: 0,
                limit: 100,
            },
            headers: {
                "X-USER-ID": userId,
                "X-REQUEST-SIGNATURE": signature,
                "X-REQUEST-PAYLOAD": payload,
            },
        });

        if (res.data == null) {
            return [];
        }

        const sessions: Session[] = res.data.map((session: any) =>
            Object.assign(
                new Session("", "", 0, [], "", 0, 0, 0, "", "", 0, "", 0),
                session
            )
        );

        return sessions;
    }

    async getSession(sessionId: string): Promise<Session> {
        const { userId, signature, payload } = await signWithToken();
        const res = await this.instance.get(`/session/${sessionId}`, {
            headers: {
                "X-USER-ID": userId,
                "X-REQUEST-SIGNATURE": signature,
                "X-REQUEST-PAYLOAD": payload,
            },
        });

        const session: Session = Object.assign(
            new Session("", "", 0, [], "", 0, 0, 0, "", "", 0, "", 0),
            res.data
        );

        return session;
    }

    async topupSessionBalance(sessionId: string, amountToAdd: number): Promise<void> {
        const { userId, signature, payload } = await signWithToken();
        const res = await this.instance.post(
            `/session/${sessionId}/topup`,
            {},
            {
                params: {
                    amount: amountToAdd
                },
                headers: {
                    "X-USER-ID": userId,
                    "X-REQUEST-SIGNATURE": signature,
                    "X-REQUEST-PAYLOAD": payload,
                },
            }
        );

        if (res.status === 200) {
            return;
        }

        throw res.data;
    }

    async updateSessionResolution(sessionId: string, resolution: string): Promise<void> {
        const { userId, signature, payload } = await signWithToken();
        const res = await this.instance.patch(
            `/session/${sessionId}/resolution`,
            {},
            {
                params: { resolution },
                headers: {
                    "X-USER-ID": userId,
                    "X-REQUEST-SIGNATURE": signature,
                    "X-REQUEST-PAYLOAD": payload,
                },
            }
        );

        if (res.status === 200) {
            return;
        }

        throw res.data;
    }

    async updateSessionStopTime(sessionId: string, stopTime: number): Promise<void> {
        const { userId, signature, payload } = await signWithToken();
        const res = await this.instance.patch(
            `/session/${sessionId}/stopTime`,
            {},
            {
                params: { stopTime },
                headers: {
                    "X-USER-ID": userId,
                    "X-REQUEST-SIGNATURE": signature,
                    "X-REQUEST-PAYLOAD": payload,
                },
            }
        );

        if (res.status === 200) {
            return;
        }

        throw res.data;
    }

    async updateSession(
        sessionId: string,
        name?: string,
        description?: string,
        strategyId?: string,
        endDate?: number
    ): Promise<void> {
        const { userId, signature, payload } = await signWithToken();
        const res = await this.instance.patch(
            `/session/${sessionId}`,
            {
                name,
                description,
                strategyId,
                endDate,
            },
            {
                headers: {
                    "X-USER-ID": userId,
                    "X-REQUEST-SIGNATURE": signature,
                    "X-REQUEST-PAYLOAD": payload,
                },
            }
        );

        if (res.status === 200) {
            return;
        }

        throw res.data;
    }

    async deleteSession(sessionId: string): Promise<void> {
        const { userId, signature, payload } = await signWithToken();
        const res = await this.instance.delete(`/session/${sessionId}`, {
            headers: {
                "X-USER-ID": userId,
                "X-REQUEST-SIGNATURE": signature,
                "X-REQUEST-PAYLOAD": payload,
            },
        });

        if (res.status === 200) {
            return;
        }

        throw res.data;
    }

    async createStrategy(name: string, description: string): Promise<Strategy> {
        const { userId, signature, payload } = await signWithToken();
        const res = await this.instance.post(
            "/strategy",
            {
                name,
                description,
            },
            {
                headers: {
                    "X-USER-ID": userId,
                    "X-REQUEST-SIGNATURE": signature,
                    "X-REQUEST-PAYLOAD": payload,
                },
            }
        );

        const strategy: Strategy = Object.assign(
            new Strategy("", "", "", "", 0, [], []),
            res.data
        );

        return strategy;
    }

    async listStrategies(strategyName?: string): Promise<Strategy[]> {
        const { userId, signature, payload } = await signWithToken();
        const res = await this.instance.get("/strategies", {
            params: {
                strategyName,
                lastIndex: 0,
                limit: 100,
            },
            headers: {
                "X-USER-ID": userId,
                "X-REQUEST-SIGNATURE": signature,
                "X-REQUEST-PAYLOAD": payload,
            },
        });

        if (res.data == null) {
            return [];
        }

        const strategies: Strategy[] = res.data.map((strategy: any) =>
            Object.assign(new Strategy("", "", "", "", 0, [], []), strategy)
        );

        return strategies;
    }

    async getStrategy(strategyId: string): Promise<Strategy> {
        const { userId, signature, payload } = await signWithToken();
        const res = await this.instance.get(`/strategy/${strategyId}`, {
            headers: {
                "X-USER-ID": userId,
                "X-REQUEST-SIGNATURE": signature,
                "X-REQUEST-PAYLOAD": payload,
            },
        });

        const strategy: Strategy = Object.assign(
            new Strategy("", "", "", "", 0, [], []),
            res.data
        );

        return strategy;
    }

    async updateStrategy(
        strategyId: string,
        strategyName?: string,
        strategyDescription?: string
    ): Promise<void> {
        const { userId, signature, payload } = await signWithToken();
        const res = await this.instance.patch(
            `/strategy/${strategyId}`,
            {
                strategyName,
                strategyDescription,
            },
            {
                headers: {
                    "X-USER-ID": userId,
                    "X-REQUEST-SIGNATURE": signature,
                    "X-REQUEST-PAYLOAD": payload,
                },
            }
        );

        if (res.status === 200) {
            return;
        }

        throw res.data;
    }

    async deleteStrategy(strategyId: string): Promise<void> {
        const { userId, signature, payload } = await signWithToken();
        const res = await this.instance.delete(`/strategy/${strategyId}`, {
            headers: {
                "X-USER-ID": userId,
                "X-REQUEST-SIGNATURE": signature,
                "X-REQUEST-PAYLOAD": payload,
            },
        });

        if (res.status === 200) {
            return;
        }

        throw res.data;
    }

    async createRuleGroup(name: string, description: string, strategyId: string): Promise<RuleGroup> {
        const { userId, signature, payload } = await signWithToken();
        const res = await this.instance.post(
            `/ruleGroup`,
            {
                name,
                description,
                strategyId
            },
            {
                headers: {
                    "X-USER-ID": userId,
                    "X-REQUEST-SIGNATURE": signature,
                    "X-REQUEST-PAYLOAD": payload,
                },
            }
        );

        const ruleGroup: RuleGroup = Object.assign(
            new RuleGroup("", "", [], "", "", 0, ""),
            res.data
        );

        return ruleGroup;
    }

    async listRuleGroups(strategyId: string): Promise<RuleGroup[]> {
        const { userId, signature, payload } = await signWithToken();
        const res = await this.instance.get(`/ruleGroups`, {
            params: {
                strategyId,
                lastIndex: 0,
                limit: 100,
            },
            headers: {
                "X-USER-ID": userId,
                "X-REQUEST-SIGNATURE": signature,
                "X-REQUEST-PAYLOAD": payload,
            },
        });

        if (res.data == null) {
            return [];
        }

        const ruleGroups: RuleGroup[] = res.data.map((ruleGroup: any) =>
            Object.assign(new RuleGroup("", "", [], "", "", 0, ""), ruleGroup)
        );

        return ruleGroups;
    }

    async getRuleGroup(groupId: string): Promise<RuleGroup> {
        const { userId, signature, payload } = await signWithToken();
        const res = await this.instance.get(`/ruleGroup/${groupId}`, {
            headers: {
                "X-USER-ID": userId,
                "X-REQUEST-SIGNATURE": signature,
                "X-REQUEST-PAYLOAD": payload,
            },
        });

        const ruleGroup: RuleGroup = Object.assign(
            new RuleGroup("", "", [], "", "", 0, ""),
            res.data
        );

        return ruleGroup;
    }

    async updateRuleGroup(groupId: string, groupName?: string, groupDescription?: string): Promise<void> {
        const { userId, signature, payload } = await signWithToken();
        const res = await this.instance.patch(
            `/ruleGroup/${groupId}`,
            {
                groupName,
                groupDescription,
            },
            {
                headers: {
                    "X-USER-ID": userId,
                    "X-REQUEST-SIGNATURE": signature,
                    "X-REQUEST-PAYLOAD": payload,
                },
            }
        );

        if (res.status === 200) {
            return;
        }

        throw res.data;
    }

    async deleteRuleGroup(groupId: string): Promise<void> {
        const { userId, signature, payload } = await signWithToken();
        const res = await this.instance.delete(`/ruleGroup/${groupId}`, {
            headers: {
                "X-USER-ID": userId,
                "X-REQUEST-SIGNATURE": signature,
                "X-REQUEST-PAYLOAD": payload,
            },
        });

        if (res.status === 200) {
            return;
        }

        throw res.data;
    }

    async createRule(strategyId: string, groupId: string, name: string, description: string): Promise<Rule> {
        const { userId, signature, payload } = await signWithToken();
        const res = await this.instance.post(
            `/rule`,
            {
                name,
                description,
                strategyId
            },
            {
                params: {
                    groupId
                },
                headers: {
                    "X-USER-ID": userId,
                    "X-REQUEST-SIGNATURE": signature,
                    "X-REQUEST-PAYLOAD": payload,
                },
            }
        );

        const rule: Rule = Object.assign(
            new Rule("", "", "", "", "", 0, ""),
            res.data
        );

        return rule;
    }

    async listRules(groupId: string): Promise<Rule[]> {
        const { userId, signature, payload } = await signWithToken();
        const res = await this.instance.get(`/rules`, {
            params: {
                groupId,
                lastIndex: 0,
                limit: 100,
            },
            headers: {
                "X-USER-ID": userId,
                "X-REQUEST-SIGNATURE": signature,
                "X-REQUEST-PAYLOAD": payload,
            },
        });

        if (res.data == null) {
            return [];
        }

        const rules: Rule[] = res.data.map((rule: any) =>
            Object.assign(new Rule("", "", "", "", "", 0, ""), rule)
        );

        return rules;
    }

    async getRule(ruleId: string): Promise<Rule> {
        const { userId, signature, payload } = await signWithToken();
        const res = await this.instance.get(`/rule/${ruleId}`, {
            headers: {
                "X-USER-ID": userId,
                "X-REQUEST-SIGNATURE": signature,
                "X-REQUEST-PAYLOAD": payload,
            },
        });

        const rule: Rule = Object.assign(
            new Rule("", "", "", "", "", 0, ""),
            res.data
        );

        return rule;
    }

    async updateRule(ruleId: string, ruleName?: string, ruleDescription?: string): Promise<void> {
        const { userId, signature, payload } = await signWithToken();
        const res = await this.instance.patch(
            `/rule/${ruleId}`,
            {
                ruleName,
                ruleDescription,
            },
            {
                headers: {
                    "X-USER-ID": userId,
                    "X-REQUEST-SIGNATURE": signature,
                    "X-REQUEST-PAYLOAD": payload,
                },
            }
        );

        if (res.status === 200) {
            return;
        }

        throw res.data;
    }

    async deleteRule(ruleId: string): Promise<void> {
        const { userId, signature, payload } = await signWithToken();
        const res = await this.instance.delete(`/rule/${ruleId}`, {
            headers: {
                "X-USER-ID": userId,
                "X-REQUEST-SIGNATURE": signature,
                "X-REQUEST-PAYLOAD": payload,
            },
        });

        if (res.status === 200) {
            return;
        }

        throw res.data;
    }

    async listTrades(): Promise<Trade[]> {
        const { userId, signature, payload } = await signWithToken();
        const res = await this.instance.get("/trades", {
            params: {
                lastIndex: 0,
                limit: 100,
            },
            headers: {
                "X-USER-ID": userId,
                "X-REQUEST-SIGNATURE": signature,
                "X-REQUEST-PAYLOAD": payload,
            },
        });

        if (res.data == null) {
            return [];
        }

        const trades: Trade[] = res.data.map((trade: any) =>
            Object.assign(new Trade("", "", "", 0, 0, 0, 0, [], [], [], "", [], "", "", ""), trade)
        );

        return trades;
    }

    async listSessionTrades(sessionId: string): Promise<Trade[]> {
        const { userId, signature, payload } = await signWithToken();
        const res = await this.instance.get(`/trades/session/${sessionId}`, {
            params: {
                lastIndex: 0,
                limit: 100,
            },
            headers: {
                "X-USER-ID": userId,
                "X-REQUEST-SIGNATURE": signature,
                "X-REQUEST-PAYLOAD": payload,
            },
        });

        if (res.data == null) {
            return [];
        }

        const trades: Trade[] = res.data.map((trade: any) =>
            Object.assign(new Trade("", "", "", 0, 0, 0, 0, [], [], [], "", [], "", "", ""), trade)
        );

        return trades;
    }

    async listStrategyTrades(strategyId: string): Promise<Trade[]> {
        const { userId, signature, payload } = await signWithToken();
        const res = await this.instance.get(`/trades/strategy/${strategyId}`, {
            params: {
                lastIndex: 0,
                limit: 100,
            },
            headers: {
                "X-USER-ID": userId,
                "X-REQUEST-SIGNATURE": signature,
                "X-REQUEST-PAYLOAD": payload,
            },
        });

        if (res.data == null) {
            return [];
        }

        const trades: Trade[] = res.data.map((trade: any) =>
            Object.assign(new Trade("", "", "", 0, 0, 0, 0, [], [], [], "", [], "", "", ""), trade)
        );

        return trades;
    }

    async getTrade(tradeId: string): Promise<Trade> {
        const { userId, signature, payload } = await signWithToken();
        const res = await this.instance.get(`/trade/${tradeId}`, {
            headers: {
                "X-USER-ID": userId,
                "X-REQUEST-SIGNATURE": signature,
                "X-REQUEST-PAYLOAD": payload,
            },
        });

        const trade: Trade = Object.assign(
            new Trade("", "", "", 0, 0, 0, 0, [], [], [], "", [], "", "", ""),
            res.data
        );

        return trade;
    }

    async updateTrade(tradeId: string, profitTarget?: number, stopLoss?: number, setups?: string[], mistakes?: string[], custom?: string[], rulesFollowed?: string[]): Promise<void> {
        const { userId, signature, payload } = await signWithToken();
        const res = await this.instance.patch(
            `/trade/${tradeId}`,
            {
                profitTarget,
                stopLoss,
                setups,
                mistakes,
                custom,
                rulesFollowed
            },
            {
                headers: {
                    "X-USER-ID": userId,
                    "X-REQUEST-SIGNATURE": signature,
                    "X-REQUEST-PAYLOAD": payload,
                },
            }
        );

        if (res.status === 200) {
            return;
        }

        throw res.data;
    }

    async closeTrade(tradeId: string, closePrice: number, closeTime: number): Promise<void> {
        const { userId, signature, payload } = await signWithToken();
        const res = await this.instance.post(`/trade/${tradeId}/close`,
            {
                closePrice,
                closeTime
            },
            {
                headers: {
                    "X-USER-ID": userId,
                    "X-REQUEST-SIGNATURE": signature,
                    "X-REQUEST-PAYLOAD": payload,
                },
            });

        if (res.status === 200) {
            return;
        }

        throw res.data;
    }

    async takeOrder(sessionId: string, side: string, orderType: string, price: number, amount: number, createdSessionTime: number, product: string, tags: string[], stopLoss?: number, takeProfit?: number, strategyId?: string): Promise<Order> {
        const { userId, signature, payload } = await signWithToken();
        const res = await this.instance.post(
            `/order`,
            {
                side,
                orderType,
                price,
                amount,
                stopLoss,
                takeProfit,
                createdSessionTime,
                product,
                tags,
                strategyId
            },
            {
                params: { sessionId },
                headers: {
                    "X-USER-ID": userId,
                    "X-REQUEST-SIGNATURE": signature,
                    "X-REQUEST-PAYLOAD": payload,
                },
            }
        );

        const order: Order = Object.assign(
            new Order("", "", "", "", 0, 0, "", "", 0, 0, 0, "", "", 0, 0, []),
            res.data
        );

        return order;
    }

    async triggerOrder(sessionId: string, orderId: string, triggerTime: number): Promise<Order> {
        const { userId, signature, payload } = await signWithToken();
        const res = await this.instance.post(`/order/${orderId}/trigger`,
            {},
            {
                params: {
                    sessionId,
                    time: triggerTime
                },
                headers: {
                    "X-USER-ID": userId,
                    "X-REQUEST-SIGNATURE": signature,
                    "X-REQUEST-PAYLOAD": payload,
                },
            });

        const order: Order = Object.assign(
            new Order("", "", "", "", 0, 0, "", "", 0, 0, 0, "", "", 0, 0, []),
            res.data
        );

        return order;
    }

    async listOrders(sessionId: string): Promise<Order[]> {
        const { userId, signature, payload } = await signWithToken();
        const res = await this.instance.get(`/orders`, {
            params: {
                sessionId,
                lastIndex: 0,
                limit: 100,
            },
            headers: {
                "X-USER-ID": userId,
                "X-REQUEST-SIGNATURE": signature,
                "X-REQUEST-PAYLOAD": payload,
            },
        });

        if (res.data == null) {
            return [];
        }

        const orders: Order[] = res.data.map((trade: any) =>
            Object.assign(new Order("", "", "", "", 0, 0, "", "", 0, 0, 0, "", "", 0, 0, []), trade)
        );

        return orders;
    }

    async listTradeOrders(sessionId: string, tradeId: string): Promise<Order[]> {
        const { userId, signature, payload } = await signWithToken();
        const res = await this.instance.get(`/orders/trade/${tradeId}`, {
            params: {
                sessionId,
                lastIndex: 0,
                limit: 100,
            },
            headers: {
                "X-USER-ID": userId,
                "X-REQUEST-SIGNATURE": signature,
                "X-REQUEST-PAYLOAD": payload,
            },
        });

        if (res.data == null) {
            return [];
        }

        const orders: Order[] = res.data.map((trade: any) =>
            Object.assign(new Order("", "", "", "", 0, 0, "", "", 0, 0, 0, "", "", 0, 0, []), trade)
        );

        return orders;
    }

    async listPassedOrders(sessionId: string): Promise<Order[]> {
        const { userId, signature, payload } = await signWithToken();
        const res = await this.instance.get(`orders/passed`, {
            params: {
                sessionId,
                lastIndex: 0,
                limit: 100,
            },
            headers: {
                "X-USER-ID": userId,
                "X-REQUEST-SIGNATURE": signature,
                "X-REQUEST-PAYLOAD": payload,
            },
        });

        if (res.data == null) {
            return [];
        }

        const orders: Order[] = res.data.map((trade: any) =>
            Object.assign(new Order("", "", "", "", 0, 0, "", "", 0, 0, 0, "", "", 0, 0, []), trade)
        );

        return orders;
    }

    async listAwaitingOrders(sessionId: string): Promise<Order[]> {
        const { userId, signature, payload } = await signWithToken();
        const res = await this.instance.get(`orders/awaiting`, {
            params: {
                sessionId,
                lastIndex: 0,
                limit: 100,
            },
            headers: {
                "X-USER-ID": userId,
                "X-REQUEST-SIGNATURE": signature,
                "X-REQUEST-PAYLOAD": payload,
            },
        });

        if (res.data == null) {
            return [];
        }

        const orders: Order[] = res.data.map((trade: any) =>
            Object.assign(new Order("", "", "", "", 0, 0, "", "", 0, 0, 0, "", "", 0, 0, []), trade)
        );

        return orders;
    }

    async getOrder(orderId: string): Promise<Order> {
        const { userId, signature, payload } = await signWithToken();
        const res = await this.instance.get(`/order/${orderId}`, {
            headers: {
                "X-USER-ID": userId,
                "X-REQUEST-SIGNATURE": signature,
                "X-REQUEST-PAYLOAD": payload,
            },
        });

        const order: Order = Object.assign(
            new Order("", "", "", "", 0, 0, "", "", 0, 0, 0, "", "", 0, 0, []),
            res.data
        );

        return order;
    }

    async updateOrder(orderId: string, side?: string, entry?: number, stopLoss?: number, takeProfit?: number, tags?: string[]): Promise<void> {
        const { userId, signature, payload } = await signWithToken();
        const res = await this.instance.patch(
            `/order/${orderId}`,
            {
                side,
                entry,
                stopLoss,
                takeProfit,
                tags
            },
            {
                headers: {
                    "X-USER-ID": userId,
                    "X-REQUEST-SIGNATURE": signature,
                    "X-REQUEST-PAYLOAD": payload,
                },
            }
        );

        if (res.status === 200) {
            return;
        }

        throw res.data;
    }

    async cancelOrder(orderId: string): Promise<void> {
        const { userId, signature, payload } = await signWithToken();
        const res = await this.instance.post(`/order/${orderId}/cancel`, {}, {
            headers: {
                "X-USER-ID": userId,
                "X-REQUEST-SIGNATURE": signature,
                "X-REQUEST-PAYLOAD": payload,
            },
        });

        if (res.status === 200) {
            return;
        }

        throw res.data;
    }

    async deleteOrder(orderId: string): Promise<void> {
        const { userId, signature, payload } = await signWithToken();
        const res = await this.instance.delete(`/order/${orderId}`, {
            headers: {
                "X-USER-ID": userId,
                "X-REQUEST-SIGNATURE": signature,
                "X-REQUEST-PAYLOAD": payload,
            },
        });

        if (res.status === 200) {
            return;
        }

        throw res.data;
    }

    async listAllSymbols(): Promise<BasicSymbolInfo[]> {
        const { userId, signature, payload } = await signWithToken();
        const res = await this.candleDataInstance.get("/pairs", {
            headers: {
                "X-USER-ID": userId,
                "X-REQUEST-SIGNATURE": signature,
                "X-REQUEST-PAYLOAD": payload,
            },
        });

        if (res.data == null) {
            return [];
        }

        const symbols: BasicSymbolInfo[] = res.data.map((symbol: any) => Object.assign(
            new BasicSymbolInfo("", "", "", "", 0, 0),
            symbol
        ));

        return symbols;
    }
}
