import { useRecoilState } from "recoil";
import { ordersState } from "../../../../../state/backtesting/atoms/orders";

export default function AwaitingOrders() {
    const [orders, setOrders] = useRecoilState(ordersState);

    return (
        <div className="bg-gray-950 h-full">
            <div>
                <div className="bg-gray-950">
                    <div className="overflow-x-auto">
                        <div className="inline-block min-w-full ">
                            <table className="min-w-full divide-y divide-gray-700">
                                <thead>
                                    <tr>
                                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-white">
                                            Side
                                        </th>
                                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-white">
                                            State
                                        </th>
                                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-white">
                                            Type
                                        </th>
                                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-white">
                                            Amount
                                        </th>
                                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-white">
                                            Price
                                        </th>
                                        <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-white sm:pl-0">
                                            Creation
                                        </th>
                                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-white">
                                            Execution
                                        </th>
                                        <th scope="col" className="py-3.5 pl-3">
                                            <span className="sr-only">Details</span>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className="divide-y divide-gray-800">
                                    {orders.filter((order) => order.state == "awaiting").map((order) => (
                                        <tr key={order.id}>

                                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-300">{order.side}</td>
                                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-300">{order.state}</td>
                                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-300">
                                                {order.orderType}
                                            </td>
                                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-300">
                                                {order.amount}
                                            </td>
                                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-300">
                                                {order.price}
                                            </td>
                                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-300">
                                                {new Date(order.createdSessionTime * 1000).toUTCString()}
                                            </td>
                                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-300">
                                                {new Date(order.executionSessionTime * 1000).toUTCString()}
                                            </td>
                                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-300">
                                                <a href="#" className="text-indigo-400 hover:text-indigo-300">
                                                    Details<span className="sr-only">, {order.id}</span>
                                                </a>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}