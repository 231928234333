import { useLoaderData, useNavigate } from "react-router-dom";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { backtestingManagerState } from "../../../../../../state/backtesting/backtesting_manager_state";
import { sessionsState } from "../../../../../../state/backtesting/atoms/sessions";
import { strategiesState } from "../../../../../../state/backtesting/atoms/strategies";
import { currentSessionState } from "../../../../../../state/backtesting/atoms/current_session";
import { useEffect, useState } from "react";
import { currentStrategyState } from "../../../../../../state/backtesting/atoms/current_strategy";
import CreateSessionModal from "./components/CreateSessionModal";

function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(' ')
}

export default function StrategySessions() {
    const navigate = useNavigate();
    const backtestingManager = useRecoilValue(backtestingManagerState);
    const [sessions, setSessions] = useRecoilState(sessionsState);
    const setCurrentSession = useSetRecoilState(currentSessionState);
    const currentStrategy = useRecoilValue(currentStrategyState);
    const [createSessionOpen, setOpenCreateSession] = useState(false);

    useEffect(() => {
        backtestingManager.listSessions(setSessions);
    }, []);

    return (
        <>
            <CreateSessionModal backtestingManager={backtestingManager} setSessions={setSessions} open={createSessionOpen} setOpen={setOpenCreateSession} />
            <div className="flex flex-row justify-between items-baseline">
                <h2 className="text-gray-50 pt-3 pb-2 text-3xl">Sessions</h2>
                <div className='mt-10 mb-3'>
                    <button
                        type="button"
                        className="mx-4 rounded-md bg-indigo-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
                        onClick={() => setOpenCreateSession(true)}
                    >
                        Create New Session
                    </button>
                    <button
                        type="button"
                        className="mx-4 rounded-md bg-indigo-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
                    >
                        Create New Strategy
                    </button>
                </div>
            </div>
            <div>
                <div className="bg-gray-900">
                    <div className="mx-auto max-w-9xl">
                        <div className="bg-gray-900 py-10">
                            <div className="px-4 sm:px-6 lg:px-6">
                                <div className="-mx-4 mt-10 ring-1 ring-gray-700 sm:mx-0 sm:rounded-lg">
                                    <table className="min-w-full divide-y divide-gray-700">
                                        <thead>
                                            <tr>
                                                <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-50 sm:pl-6">
                                                    Name
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-50 lg:table-cell"
                                                >
                                                    Product
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-50 lg:table-cell"
                                                >
                                                    Balance
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-50 lg:table-cell"
                                                >
                                                    Description
                                                </th>
                                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-50">
                                                    Strategy
                                                </th>
                                                <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                                                    <span className="sr-only">Select</span>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {sessions.filter((session) => session.strategyId == currentStrategy?.id).map((session, sessionIdx) => (
                                                <tr key={session.id}>
                                                    <td
                                                        className={classNames(
                                                            sessionIdx === 0 ? '' : 'border-t border-transparent',
                                                            'relative py-4 pl-4 pr-3 text-sm sm:pl-6',
                                                        )}
                                                    >
                                                        <div className="font-medium text-gray-50">
                                                            {session.name}
                                                        </div>
                                                        <div className="mt-1 flex flex-col text-gray-50 sm:block lg:hidden">
                                                            {session.pairs}
                                                        </div>
                                                        {sessionIdx !== 0 ? <div className="absolute -top-px left-6 right-0 h-px bg-gray-600" /> : null}
                                                    </td>
                                                    <td
                                                        className={classNames(
                                                            sessionIdx === 0 ? '' : 'border-t border-gray-600',
                                                            'hidden px-3 py-3.5 text-sm text-gray-50 lg:table-cell',
                                                        )}
                                                    >
                                                        {session.pairs}
                                                    </td>
                                                    <td
                                                        className={classNames(
                                                            sessionIdx === 0 ? '' : 'border-t border-gray-600',
                                                            'hidden px-3 py-3.5 text-sm text-gray-50 lg:table-cell',
                                                        )}
                                                    >
                                                        {session.accountBalance}
                                                    </td>
                                                    <td
                                                        className={classNames(
                                                            sessionIdx === 0 ? '' : 'border-t border-gray-600',
                                                            'hidden px-3 py-3.5 text-sm text-gray-50 lg:table-cell',
                                                        )}
                                                    >
                                                        {session.description}
                                                    </td>
                                                    <td
                                                        className={classNames(
                                                            sessionIdx === 0 ? '' : 'border-t border-gray-600',
                                                            'px-3 py-3.5 text-sm text-gray-50',
                                                        )}
                                                    >

                                                        {session.strategyId == "" ? "no strategy" : session.strategyId}
                                                    </td>
                                                    <td
                                                        className={classNames(
                                                            sessionIdx === 0 ? '' : 'border-t border-transparent',
                                                            'relative py-3.5 pl-3 pr-4 text-right text-sm font-medium sm:pr-6',
                                                        )}
                                                    >
                                                        <button
                                                            type="button"
                                                            onClick={() => {
                                                                setCurrentSession(session);
                                                                navigate(`/app/session/${session.id}`)
                                                            }}
                                                            className="inline-flex items-center rounded-md bg-gray-800 px-2.5 py-1.5 text-sm font-semibold text-gray-50 shadow-sm ring-1 ring-inset ring-gray-600 hover:bg-gray-700 disabled:cursor-not-allowed disabled:opacity-30 disabled:hover:bg-gray-900"
                                                        >
                                                            Play
                                                        </button>
                                                        {sessionIdx !== 0 ? <div className="absolute -top-px left-0 right-6 h-px bg-gray-600" /> : null}
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}