import { Dispatch, SetStateAction } from 'react'
import { Dialog, DialogBackdrop, DialogPanel, DialogTitle } from '@headlessui/react'
import { CheckIcon } from '@heroicons/react/24/outline'
import { SetterOrUpdater, useRecoilValue } from 'recoil'
import { Rule } from '../../../../../../state/backtesting/models/backtesting_models'
import IBactestingManagerBase from '../../../../../../state/backtesting/manager/backtesting_manager_base'
import { useFormik } from 'formik'
import { currentStrategyState } from '../../../../../../state/backtesting/atoms/current_strategy'

export default function CreateRuleModal(props: {
    backtestingManager: IBactestingManagerBase,
    setRules: SetterOrUpdater<Rule[]>,
    open: boolean,
    setOpen: Dispatch<SetStateAction<boolean>>,
    groupId: string
}) {
    const currentStrategy = useRecoilValue(currentStrategyState);

    const validate = (values: { name?: string, description?: string, api?: string }) => {
        const errors: { name?: string, description?: string, api?: string } = {};
        if (!values.name) {
            errors.name = "You have to enter an name";
        }

        if (!values.description) {
            errors.description = "You have to enter a password";
        }

        return errors;
    }

    const {
        handleSubmit,
        handleChange,
        handleBlur,
        touched,
        values,
        errors,
    } = useFormik({
        initialValues: {
            name: "",
            description: "",
        },
        validate,
        onSubmit: async (values) => {
            console.log(values);
            try {
                await props.backtestingManager.createRule(props.setRules, currentStrategy!.id, props.groupId, values.name!, values.description!);
                props.setOpen(false);
                return true;
            } catch (error) {
                errors.api = "failed to create strategy"
                return false;
            }
        }
    });

    return (
        <Dialog open={props.open} onClose={props.setOpen} className="relative z-10">
            <DialogBackdrop
                transition
                className="fixed inset-0 bg-transparent backdrop-blur-md transition-all data-[closed]:backdrop-blur-none data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
            />
            <form onSubmit={handleSubmit}>
                <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <DialogPanel
                            transition
                            className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-lg sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
                        >
                            <div>
                                <div className="mt-1 sm:mt-3">
                                    <DialogTitle as="h3" className="text-base font-semibold leading-6 text-gray-900">
                                        Create New Rule
                                    </DialogTitle>
                                    <div className="mt-6 flex flex-col space-y-6">
                                        <div>
                                            <label htmlFor="name" className="block text-sm font-medium leading-6 text-gray-900">
                                                Name
                                            </label>
                                            <div className="mt-2">
                                                <input
                                                    id="name"
                                                    name="name"
                                                    type="name"
                                                    placeholder="new rule"
                                                    className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                />
                                            </div>
                                        </div>
                                        <div>
                                            <label htmlFor="description" className="block text-sm font-medium leading-6 text-gray-900">
                                                Description
                                            </label>
                                            <div className="mt-2">
                                                <input
                                                    id="description"
                                                    name="description"
                                                    type="description"
                                                    placeholder="rule description"
                                                    className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                                <button
                                    type="submit"
                                    className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2"
                                >
                                    Create
                                </button>
                                <button
                                    type="button"
                                    data-autofocus
                                    onClick={() => props.setOpen(false)}
                                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                                >
                                    Cancel
                                </button>
                            </div>
                        </DialogPanel>
                    </div>
                </div>
            </form>
        </Dialog>
    )
}