import React, { useEffect } from "react";
import "./App.css";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { authenticationManagerState } from "./state/authentication/authentication_manager_state";
import { authenticatedState } from "./state/authentication/atoms/authenticated";
import { usersManagerState } from "./state/user/users_state";
import { userState } from "./state/user/atoms/user";
import { createBrowserRouter, redirect, Route, RouterProvider } from "react-router-dom";
import Landing from "./views/landing/Landing";
import InApp from "./views/app/InApp";
import BacktestingSession from "./views/app/components/backtesting_session/BacktestingSession";
import Payment from "./views/payment/Payment";
import UserInfo from "./views/app/components/settings/Settings";
import Login from "./views/login/Login";
import Signup from "./views/signup/Signup";
import InitRecoverPassword from "./views/recover_password/InitRecoverPassword";
import RecoverPassword from "./views/recover_password/RecoverPassword";
import { loadStripe } from "@stripe/stripe-js";
import { stripeState } from "./state/payment/atoms/stripe";
import { subscriptionsState } from "./state/payment/atoms/subscriptions";
import { paymentManagerState } from "./state/payment/payment_manager_state";
import Pricing from "./views/landing/Pricing";
import Company from "./views/landing/Company";
import Settings from "./views/app/components/settings/Settings";
import Dashboard from "./views/app/components/dashboard/Dashboard";
import AccountSettings from "./views/app/components/settings/components/AccountSettings";
import BillingSettings from "./views/app/components/settings/components/BillingSettings";
import Strategies from "./views/app/components/strategies/Strategies";
import StrategyDetails from "./views/app/components/strategies/strategy_details/StrategyDetails";
import RuleDetails from "./views/app/components/strategies/strategy_details/rule_group_details/rule_details/RuleDetails";
import StrategyStatistics from "./views/app/components/strategies/strategy_details/statistics/StrategyStatistics";
import StrategySessions from "./views/app/components/strategies/strategy_details/sessions/StrategySessions";
import StrategyRules from "./views/app/components/strategies/strategy_details/rules/StrategyRules";
import StrategyTrades from "./views/app/components/strategies/strategy_details/trades/StrategyTrades";
import NotFound from "./views/notfound/NotFound";


function App() {
  const authenticationManager = useRecoilValue(authenticationManagerState);
  const setAuthenticated = useSetRecoilState(authenticatedState);
  const userManager = useRecoilValue(usersManagerState);
  const setUser = useSetRecoilState(userState);
  const paymentManager = useRecoilValue(paymentManagerState);
  const setSubscriptions = useSetRecoilState(subscriptionsState);
  const setStripe = useSetRecoilState(stripeState);

  let skipAuth = false;

  useEffect(() => {
    loadStripe("pk_test_51PPNzHRpBfUMfDWP6rVlhXVIR9bCMH78DbWypH4SVIhOz1yN7ILzr5gZTpLIIdmnejZRL0BXWFiw7M9au8Yqx2to007XlaXTrt").then((s) => setStripe(s!));
  }, [])

  const mainRouteLoader = async () => {
    try {
      if (!skipAuth) {
        skipAuth = true;
        await authenticationManager.authenticate(setAuthenticated);
        const user = await userManager.getUser(setUser);
        if (user.id != "") {
          const subscriptions = await paymentManager.listSubscriptions(setSubscriptions, user.customerId);
          if (subscriptions.length > 0) {
            return redirect("/app");
          }
        }
      }
    } catch (error) {
      console.log(error)
    }
    return null;

  }

  const loginRouteLoader = async () => {
    try {
      skipAuth = true;
      await authenticationManager.authenticate(setAuthenticated);
      const user = await userManager.getUser(setUser);
      if (user.id != "") {
        const subscriptions = await paymentManager.listSubscriptions(setSubscriptions, user.customerId);
        if (subscriptions.length > 0) {
          return redirect("/app");
        }
        return redirect("/payment")
      }

    } catch (error) {
      console.log(error)
    }
    return null;

  }

  const signupRouteLoader = async () => {
    try {
      skipAuth = true;
      await authenticationManager.authenticate(setAuthenticated);
      const user = await userManager.getUser(setUser);
      if (user.id != "") {
        const subscriptions = await paymentManager.listSubscriptions(setSubscriptions, user.customerId);
        if (subscriptions.length > 0) {
          return redirect("/app");
        }
        return redirect("/payment")
      }

    } catch (error) {
      console.log(error)
    }
    return null;

  }

  const initRecoverPasswordRouteLoader = async () => {
    return null;
  }

  const recoverPasswordRouteLoader = async () => {
    return null;
  }

  const paymentRouteLoader = async () => {
    try {
      if (!skipAuth) {
        skipAuth = true;
        await authenticationManager.authenticate(setAuthenticated);
        const user = await userManager.getUser(setUser);
        if (user.id != "") {
          const subscriptions = await paymentManager.listSubscriptions(setSubscriptions, user.customerId);
          console.log(subscriptions);
          if (subscriptions.length > 0) {
            console.log("redirecting to app")
            return redirect("/app");
          }
        } else {
          return redirect("/login");
        }
      }
    } catch (error) {
      console.log(error)
      return redirect("/");
    }
    return null;

  }

  const dashboardRouteLoader = async () => {
    try {
      if (!skipAuth) {
        skipAuth = true;
        await authenticationManager.authenticate(setAuthenticated);
        const user = await userManager.getUser(setUser);
        if (user.id != "") {
          const subscriptions = await paymentManager.listSubscriptions(setSubscriptions, user.customerId);
          if (subscriptions.length > 0) {
            return null;
          }
          return redirect("/");
        }
      }
    } catch (error) {
      console.log(error)
      return redirect("/");
    }
    return null;

  }

  const sessionRouteLoader = async ({ params }: { params: any }) => {
    try {
      if (!skipAuth) {
        skipAuth = true;
        await authenticationManager.authenticate(setAuthenticated);
        const user = await userManager.getUser(setUser);
        if (user.id != "") {
          const subscriptions = await paymentManager.listSubscriptions(setSubscriptions, user.customerId);
          if (subscriptions.length > 0) {
            return null;
          }
          return redirect("/");
        }
      }
    } catch (error) {
      console.log(error)
      return redirect("/");
    }
    return null;

  }

  const logoutRouteLoader = async ({ params }: { params: any }) => {
    await authenticationManager.logout(setAuthenticated);

    return redirect("/");;

  }

  const router = createBrowserRouter([
    {
      path: "/",
      loader: mainRouteLoader,
      element: <Landing />,
    },
    {
      path: "/pricing",
      loader: mainRouteLoader,
      element: <Pricing />
    },
    {
      path: "/company",
      loader: mainRouteLoader,
      element: <Company />
    },
    {
      path: "/login",
      loader: loginRouteLoader,
      element: <Login />,
    },
    {
      path: "/signup",
      loader: signupRouteLoader,
      element: <Signup />,
    },
    {
      path: "/init_recover_password",
      loader: initRecoverPasswordRouteLoader,
      element: <InitRecoverPassword />
    },
    {
      path: "/recover_password",
      loader: recoverPasswordRouteLoader,
      element: <RecoverPassword />
    },
    {
      path: "/payment",
      loader: paymentRouteLoader,
      element: <Payment />,
    },
    {
      path: "/app",
      loader: dashboardRouteLoader,
      element: <InApp />,
      children: [
        {
          path: "",
          loader: dashboardRouteLoader,
          element: <Dashboard />,
          children: []
        },
        {
          path: "strategies",
          loader: dashboardRouteLoader,
          element: <Strategies />,
        },
        {
          path: "/app/strategy/:strategyId",
          loader: dashboardRouteLoader,
          element: <StrategyDetails />,
          children: [
            {
              path: "statistics",
              loader: dashboardRouteLoader,
              element: <StrategyStatistics />
            },
            {
              path: "sessions",
              loader: dashboardRouteLoader,
              element: <StrategySessions />
            },
            {
              path: "rules",
              loader: dashboardRouteLoader,
              element: <StrategyRules />
            },
            {
              path: "trades",
              loader: dashboardRouteLoader,
              element: <StrategyTrades />
            },
            {
              path: "rule/:ruleId",
              loader: dashboardRouteLoader,
              element: <RuleDetails />
            },
          ]
        },
        {
          path: "settings",
          loader: dashboardRouteLoader,
          element: <Settings />,
          children: [
            {
              path: "account",
              loader: dashboardRouteLoader,
              element: <AccountSettings />
            },
            {
              path: "billing",
              loader: dashboardRouteLoader,
              element: <BillingSettings />
            }
          ]
        },
      ]
    },
    {
      path: "/app/logout",
      loader: logoutRouteLoader,
      element: <Landing />
    },
    {
      path: "/app/session/:sessionId",
      loader: sessionRouteLoader,
      element: <BacktestingSession />,
    },
    {
      path: "*",
      element: <NotFound />
    }
  ]);

  return (
    <div>
      <RouterProvider fallbackElement={<h1>Loading...</h1>} router={router} />
    </div>
  )
}

export default App;
