import { Bar, DOMCallback, ErrorCallback, GetMarksCallback, HistoryCallback, IChartingLibraryWidget, IDatafeedChartApi, IExternalDatafeed, LibrarySymbolInfo, Mark, OnReadyCallback, PeriodParams, ResolutionString, ResolveCallback, SearchSymbolsCallback, ServerTimeCallback, SubscribeBarsCallback, SymbolResolveExtension, TimescaleMark } from "charting_library";
import IReplayDatafeedAPI from "./api/replay_datafeed_api_base";
import IReplayManager from "./manager/replay_manager_base";
import { computeBar, convertResolutionToSeconds } from "./manager/replay_manager";

export default class ReplayDatafeed<T extends IReplayDatafeedAPI, U extends IReplayManager> implements IExternalDatafeed, IDatafeedChartApi {
    public lastBar?: Bar = undefined;
    private requesting: boolean = false;
    private secondRequest: boolean = false;
    private previousAppliedResolution?: ResolutionString;

    constructor(protected readonly api: T, protected replayManager: U) { }

    onReady(callback: OnReadyCallback): void {
        this.api.config().then((c) => callback(c));
    }

    getMarks?(symbolInfo: LibrarySymbolInfo, from: number, to: number, onDataCddssdllback: GetMarksCallback<Mark>, resolution: ResolutionString): void {
        throw new Error("Method not implemented.");
    }

    getTimescaleMarks?(symbolInfo: LibrarySymbolInfo, from: number, to: number, onDataCallback: GetMarksCallback<TimescaleMark>, resolution: ResolutionString): void {
        throw new Error("Method not implemented.");
    }

    getServerTime?(callback: ServerTimeCallback): void {
        this.api.time().then((t) => callback(parseInt(t)))
    }

    searchSymbols(userInput: string, exchange: string, symbolType: string, onResult: SearchSymbolsCallback): void {
        this.api.search(userInput, symbolType, exchange, 30).then((symbols) => onResult(symbols))
    }

    resolveSymbol(symbolName: string, onResolve: ResolveCallback, onError: ErrorCallback, extension?: SymbolResolveExtension | undefined): void {
        this.api.symbols(symbolName).then((s) => onResolve(s)).catch((err) => onError(err));
    }

    getBars(symbolInfo: LibrarySymbolInfo, resolution: ResolutionString, periodParams: PeriodParams, onResult: HistoryCallback, onError: ErrorCallback): void {
        console.log("this is very important:", periodParams.firstDataRequest);
        if (this.requesting) {
            setTimeout(() => {
                this.getBars(symbolInfo, resolution, periodParams, onResult, onError);
            }, 200);
            return;
        };

        this.requesting = true;

        let from: number, to: number;
        if (periodParams.firstDataRequest) {
            to = this.replayManager.lastTick!;
            from = to - (convertResolutionToSeconds(resolution) * 300)
        } else {
            from = periodParams.from;
            to = periodParams.to;
        }

        let appliedResolution = resolution;
        this.previousAppliedResolution ??= resolution;

        if (periodParams.firstDataRequest) {
            if (this.replayManager.previousResolution != undefined) {
                if (convertResolutionToSeconds(this.replayManager.previousResolution) < convertResolutionToSeconds(resolution)) {
                    // this.replayManager.updated = false;
                    console.log(this.replayManager.smallestResolution)

                    appliedResolution = this.replayManager.smallestResolution ?? this.replayManager.previousResolution;
                    to = this.replayManager.lastTick!;

                    console.log("///////////////////////// to:", to, appliedResolution)
                    console.log(to - (to % convertResolutionToSeconds(resolution)))

                    from = to - (to % convertResolutionToSeconds(resolution))

                    this.api.history(symbolInfo.name, `${from}`, `${to}`, appliedResolution, periodParams.countBack)
                        .then(([bars, done]) => {
                            this.secondRequest = true;
                            console.log("////////////////////////////// new last tick from history:", bars[bars.length - 1].time / 1000);

                            this.replayManager.lastTick = bars[bars.length - 1].time / 1000;

                            const bar = computeBar(bars, appliedResolution);

                            this.replayManager.lastBar = bar
                            this.lastBar = bar;

                            console.log(bar);

                            onResult([bar], { noData: done ?? false });
                        })
                        .catch((err) => onError(err))
                        .finally(() => this.requesting = false);

                    return;
                } else if (convertResolutionToSeconds(this.replayManager.previousResolution) > convertResolutionToSeconds(resolution)) {

                    appliedResolution = this.replayManager.smallestResolution ?? resolution;

                    console.log("////////////////////////////: lastBar and last tick", this.lastBar!.time / 1000, this.replayManager.lastTick)

                    to = this.replayManager.lastTick! + (
                        this.previousAppliedResolution != appliedResolution
                            ? convertResolutionToSeconds(this.replayManager.previousResolution) - convertResolutionToSeconds(appliedResolution)
                            : 0
                    );

                    this.previousAppliedResolution = appliedResolution;

                    from = to - convertResolutionToSeconds(this.replayManager.previousResolution)

                    this.replayManager.updated = false;

                    console.log("///////////////////////// to:", to, appliedResolution)

                    this.api.history(symbolInfo.name, `${from}`, `${to}`, appliedResolution, periodParams.countBack)
                        .then(([bars, done]) => {
                            this.secondRequest = true;
                            this.replayManager.lastBar = bars[bars.length - 1]
                            this.lastBar = bars[bars.length - 1];
                            this.replayManager.lastTick = bars[bars.length - 1].time / 1000;
                            console.log("////////////////////////////// new last tick from history:", bars[bars.length - 1].time / 1000)

                            onResult(bars, { noData: done ?? false });
                        })
                        .catch((err) => onError(err))
                        .finally(() => this.requesting = false);
                }
            }
        }

        this.api.history(symbolInfo.name, `${from}`, `${to}`, appliedResolution, periodParams.countBack)
            .then(([bars, done]) => {
                if (periodParams.firstDataRequest) {
                    this.secondRequest = true;
                    this.replayManager.lastBar = bars[bars.length - 1]
                    this.lastBar = bars[bars.length - 1];
                    this.replayManager.lastTick = bars[bars.length - 1].time / 1000;
                    console.log("////////////////////////////// new last tick from history:", bars[bars.length - 1].time / 1000)
                } else if (
                    this.secondRequest
                ) {
                    this.secondRequest = false;

                    if (this.replayManager.previousResolution != undefined) {
                        if (convertResolutionToSeconds(this.replayManager.previousResolution) < convertResolutionToSeconds(resolution)) {
                            bars.splice(bars.length - 1);
                        }
                    }
                }

                onResult(bars, { noData: done ?? false });
            })
            .catch((err) => onError(err))
            .finally(() => this.requesting = false);
    }

    subscribeBars(symbolInfo: LibrarySymbolInfo, resolution: ResolutionString, onTick: SubscribeBarsCallback, listenerGuid: string, onResetCacheNeededCallback: () => void): void {
        this.replayManager.symbol = symbolInfo.name;
        this.replayManager.ontick = onTick;
        this.replayManager.listenerGuid = listenerGuid;
        this.replayManager.resolution = resolution;
        this.replayManager.previousResolution = resolution;
        this.replayManager.onResetCacheNeededCallback = onResetCacheNeededCallback;
        this.replayManager.speed ??= 1000;

        this.replayManager.setupReplay(this.replayManager.from!, this.replayManager.baseTo!, resolution, this.replayManager.speed);
    }

    unsubscribeBars(listenerGuid: string): void {
        this.replayManager.resolutionChanged = true;
        this.replayManager.lastBar = undefined;
        this.replayManager.stopReplay(listenerGuid);
    }

    subscribeDepth?(symbol: string, callback: DOMCallback): string {
        throw new Error("Method not implemented.");
    }

    unsubscribeDepth?(subscriberUID: string): void {
        throw new Error("Method not implemented.");
    }

    getVolumeProfileResolutionForPeriod?(currentResolution: ResolutionString, from: number, to: number, symbolInfo: LibrarySymbolInfo): ResolutionString {
        throw new Error("Method not implemented.");
    }

    setCurrentChart(chart: IChartingLibraryWidget) {
        this.replayManager.setCurrentChart(chart);
    }

    getCurrentChart() {
        return this.replayManager.currentChart;
    }

    resetCurrentChart() {
        this.replayManager.onResetCacheNeededCallback!();
        this.replayManager.currentChart!.activeChart().resetData();
    }

    setTimeframe(to: number, from?: number) {
        this.replayManager.from = from;
        this.replayManager.baseTo = to;
        this.replayManager.lastTick = to;
    }

    pauseReplay() {
        this.replayManager.pauseReplay()
    }

    startReplay() {
        this.replayManager.startReplay();
    }

    revertReplay() {
        this.replayManager.revertReplay();
    }

    async updateSpeed(speed: number): Promise<void> {
        await this.replayManager.updateReplaySpeed(speed);
    }
}