import { useNavigate } from "react-router-dom";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { backtestingManagerState } from "../../../../../../state/backtesting/backtesting_manager_state";
import { useEffect, useState } from "react";
import { currentStrategyState } from "../../../../../../state/backtesting/atoms/current_strategy";
import { tradesState } from "../../../../../../state/backtesting/atoms/trades";
import { currentSessionState } from "../../../../../../state/backtesting/atoms/current_session";
import { sessionsState } from "../../../../../../state/backtesting/atoms/sessions";

function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(' ')
}

export default function StrategyTrades() {
    const navigate = useNavigate();
    const backtestingManager = useRecoilValue(backtestingManagerState);
    const [trades, setTrades] = useRecoilState(tradesState);
    const currentStrategy = useRecoilValue(currentStrategyState);
    const setCurrentSession = useSetRecoilState(currentSessionState);
    const setSessions = useSetRecoilState(sessionsState)

    useEffect(() => {
        if (currentStrategy != undefined) {
            backtestingManager.listStrategyTrades(setTrades, currentStrategy.id);
        }
    }, [currentStrategy]);

    return (
        <>
            <div className="flex flex-row justify-between items-baseline mt-10 mb-3">
                <h2 className="text-gray-50 pt-3 pb-2 text-3xl">Trades</h2>
            </div>
            <div>
                <div className="bg-gray-900">
                    <div className="mx-auto max-w-9xl">
                        <div className="bg-gray-900 py-10">
                            <div className="px-4 sm:px-6 lg:px-6">
                                <div className="-mx-4 mt-10 ring-1 ring-gray-700 sm:mx-0 sm:rounded-lg">
                                    <table className="min-w-full divide-y divide-gray-700">
                                        <thead>
                                            <tr>
                                                <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-50 sm:pl-6">
                                                    Name
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-50 lg:table-cell"
                                                >
                                                    Product
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-50 lg:table-cell"
                                                >
                                                    Balance
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-50 lg:table-cell"
                                                >
                                                    Description
                                                </th>
                                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-50">
                                                    Strategy
                                                </th>
                                                <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                                                    <span className="sr-only">Select</span>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {trades.filter((trade) => trade.strategyId == currentStrategy?.id).map((trade, tradeIdx) => (
                                                <tr key={trade.id}>
                                                    <td
                                                        className={classNames(
                                                            tradeIdx === 0 ? '' : 'border-t border-transparent',
                                                            'relative py-4 pl-4 pr-3 text-sm sm:pl-6',
                                                        )}
                                                    >
                                                        <div className="font-medium text-gray-50">
                                                            {trade.side}
                                                        </div>
                                                        <div className="mt-1 flex flex-col text-gray-50 sm:block lg:hidden">
                                                            {trade.product}
                                                        </div>
                                                        {tradeIdx !== 0 ? <div className="absolute -top-px left-6 right-0 h-px bg-gray-600" /> : null}
                                                    </td>
                                                    <td
                                                        className={classNames(
                                                            tradeIdx === 0 ? '' : 'border-t border-gray-600',
                                                            'hidden px-3 py-3.5 text-sm text-gray-50 lg:table-cell',
                                                        )}
                                                    >
                                                        {trade.product}
                                                    </td>
                                                    <td
                                                        className={classNames(
                                                            tradeIdx === 0 ? '' : 'border-t border-gray-600',
                                                            'hidden px-3 py-3.5 text-sm text-gray-50 lg:table-cell',
                                                        )}
                                                    >
                                                        {trade.entryTime}
                                                    </td>
                                                    <td
                                                        className={classNames(
                                                            tradeIdx === 0 ? '' : 'border-t border-gray-600',
                                                            'hidden px-3 py-3.5 text-sm text-gray-50 lg:table-cell',
                                                        )}
                                                    >
                                                        {trade.exitTime}
                                                    </td>
                                                    <td
                                                        className={classNames(
                                                            tradeIdx === 0 ? '' : 'border-t border-gray-600',
                                                            'px-3 py-3.5 text-sm text-gray-50',
                                                        )}
                                                    >

                                                        {trade.strategyId == "" ? "no strategy" : trade.strategyId}
                                                    </td>
                                                    <td
                                                        className={classNames(
                                                            tradeIdx === 0 ? '' : 'border-t border-transparent',
                                                            'relative py-3.5 pl-3 pr-4 text-right text-sm font-medium sm:pr-6',
                                                        )}
                                                    >
                                                        <button
                                                            type="button"
                                                            onClick={() => {
                                                                backtestingManager.getSession(setSessions, trade.sessionId).then((session) => {
                                                                    setCurrentSession(session);
                                                                    navigate(`/app/trade/${trade.id}`)
                                                                });
                                                            }}
                                                            className="inline-flex items-center rounded-md bg-gray-800 px-2.5 py-1.5 text-sm font-semibold text-gray-50 shadow-sm ring-1 ring-inset ring-gray-600 hover:bg-gray-700 disabled:cursor-not-allowed disabled:opacity-30 disabled:hover:bg-gray-900"
                                                        >
                                                            Replay
                                                        </button>
                                                        {tradeIdx !== 0 ? <div className="absolute -top-px left-0 right-6 h-px bg-gray-600" /> : null}
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}