export class Session {
    id: string;
    name: string;
    accountBalance: number;
    pairs: string[];
    strategyId: string;
    startDate: number;
    stopDate: number;
    endDate: number;
    resolution: string;
    userId: string;
    createdAt: number;
    description: string;
    lastChartLayoutId: number;

    constructor(id: string, name: string, accountBalance: number, pairs: string[], strategyId: string, startDate: number, stopDate: number, endDate: number, resolution: string, userId: string, createdAt: number, description: string, lastChartLayoutId: number) {
        this.id = id;
        this.name = name;
        this.accountBalance = accountBalance;
        this.pairs = pairs;
        this.strategyId = strategyId;
        this.startDate = startDate;
        this.stopDate = stopDate;
        this.endDate = endDate;
        this.resolution = resolution;
        this.userId = userId;
        this.createdAt = createdAt;
        this.description = description;
        this.lastChartLayoutId = lastChartLayoutId;
    }
}

export class Strategy {
    id: string;
    name: string;
    description: string;
    userId: string;
    createdAt: number;
    ruleGroups: string[];
    rules: string[];

    constructor(id: string, name: string, description: string, userId: string, createdAt: number, ruleGroups: string[], rules: string[]) {
        this.id = id;
        this.name = name;
        this.description = description;
        this.userId = userId;
        this.createdAt = createdAt;
        this.ruleGroups = ruleGroups;
        this.rules = rules;
    }
}

export class RuleGroup {
    constructor(
        public id: string,
        public name: string,
        public rules: string[],
        public userId: string,
        public strategyId: string,
        public created: number,
        public description: string
    ) { }
}

export class Rule {
    constructor(
        public id: string,
        public name: string,
        public description: string,
        public strategyId: string,
        public groupId: string,
        public created: number,
        public userId: string
    ) { }
}

export class Trade {
    constructor(
        public id: string,
        public userId: string,
        public sessionId: string,
        public entryTime: number,
        public exitTime: number,
        public entryRealTime: number,
        public exitRealTime: number,
        public setups: string[],
        public mistakes: string[],
        public custom: string[],
        public product: string,
        public rulesFollowed: string[],
        public side: string,
        public status: string,
        public strategyId?: string,
        public commissionFee?: number,
        public profitTarget?: number,
        public stopLoss?: number,
    ) { }
}

export class Order {
    constructor(
        public id: string,
        public userId: string,
        public side: string,
        public orderType: string,
        public price: number,
        public amount: number,
        public sessionId: string,
        public tradeId: string,
        public createdSessionTime: number,
        public createdRealtime: number,
        public riskPercentage: number,
        public state: string,
        public product: string,
        public executionSessionTime: number,
        public executionRealTime: number,
        public tags: string[],
        public stopLoss?: number,
        public takeProfit?: number,
        public strategyId?: string
    ) { }
}

export class BasicSymbolInfo {
    constructor(
        public baseName: string,
        public quoteAsset: string,
        public exchange: string,
        public name: string,
        public firstDate: number,
        public lastDate: number,
    ) { }
}