import { strategiesState } from "../../../../state/backtesting/atoms/strategies";
import { backtestingManagerState } from "../../../../state/backtesting/backtesting_manager_state";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { currentStrategyState } from "../../../../state/backtesting/atoms/current_strategy";
import CreateStrategyModal from "./components/CreateStrategyModal";
import EditStrategyModal from "./components/EditStrategyModal";
import DeleteStrategyModal from "./components/DeleteStrategyModal";

export default function Strategies() {
    const [strategies, setStrategies] = useRecoilState(strategiesState);
    const backtestingManager = useRecoilValue(backtestingManagerState);
    const setCurrentStrategy = useSetRecoilState(currentStrategyState);
    const navigate = useNavigate();

    const [strategyId, setStrategyId] = useState("");
    const [createStrategyOpen, setOpenCreateStrategy] = useState(false);
    const [deleteStrategyOpen, setOpenDeleteStrategy] = useState(false);
    const [editStrategyOpen, setOpenEditStrategy] = useState(false);

    useEffect(() => {
        backtestingManager.listStrategies(setStrategies);
    }, [])

    function classNames(...classes: string[]) {
        return classes.filter(Boolean).join(' ')
    }

    return (
        <main>
            <CreateStrategyModal backtestingManager={backtestingManager} setStrategies={setStrategies} open={createStrategyOpen} setOpen={setOpenCreateStrategy} />
            <EditStrategyModal backtestingManager={backtestingManager} setStrategies={setStrategies} open={editStrategyOpen} setOpen={setOpenEditStrategy} />
            <DeleteStrategyModal backtestingManager={backtestingManager} setStrategies={setStrategies} open={deleteStrategyOpen} setOpen={setOpenDeleteStrategy} strategyId={strategyId} />
            <div className="flex flex-row justify-between mt-8 mb-4">
                <h2 className="text-gray-50 text-4xl">Strategies</h2>
                <button
                    type="button"
                    className="mx-4 rounded-md bg-indigo-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
                    onClick={() => setOpenCreateStrategy(true)}
                >
                    Create New Strategy
                </button>
            </div>
            <div>
                <div className="bg-gray-900">
                    <div className="mx-auto max-w-9xl">
                        <div className="bg-gray-900 py-10">
                            <div className="px-4 sm:px-6 lg:px-6">
                                <div className="-mx-4 mt-10 ring-1 ring-gray-700 sm:mx-0 sm:rounded-lg">
                                    <table className="min-w-full divide-y divide-gray-700">
                                        <thead>
                                            <tr>
                                                <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-50 sm:pl-6">
                                                    Name
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-50 lg:table-cell"
                                                >
                                                    Description
                                                </th>

                                                <th
                                                    scope="col"
                                                    className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-50 lg:table-cell"
                                                >
                                                    Date
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-50 lg:table-cell"
                                                >
                                                    Nb of Rule Group
                                                </th>
                                                <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                                                    <span className="sr-only">Select</span>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {strategies.map((strategy, strategyIdx) => (
                                                <tr key={strategy.id}>
                                                    <td
                                                        className={classNames(
                                                            strategyIdx === 0 ? '' : 'border-t border-transparent',
                                                            'relative py-4 pl-4 pr-3 text-sm sm:pl-6',
                                                        )}
                                                    >
                                                        <div className="font-medium text-gray-50">
                                                            {strategy.name}
                                                        </div>
                                                        <div className="mt-1 flex flex-col text-gray-50 sm:block lg:hidden">
                                                            {strategy.description}
                                                        </div>
                                                        {strategyIdx !== 0 ? <div className="absolute -top-px left-6 right-0 h-px bg-gray-600" /> : null}
                                                    </td>
                                                    <td
                                                        className={classNames(
                                                            strategyIdx === 0 ? '' : 'border-t border-gray-600',
                                                            'hidden px-3 py-3.5 text-sm text-gray-50 lg:table-cell',
                                                        )}
                                                    >
                                                        {strategy.description}
                                                    </td>
                                                    <td
                                                        className={classNames(
                                                            strategyIdx === 0 ? '' : 'border-t border-gray-600',
                                                            'hidden px-3 py-3.5 text-sm text-gray-50 lg:table-cell',
                                                        )}
                                                    >
                                                        {strategy.createdAt}
                                                    </td>
                                                    <td
                                                        className={classNames(
                                                            strategyIdx === 0 ? '' : 'border-t border-gray-600',
                                                            'hidden px-3 py-3.5 text-sm text-gray-50 lg:table-cell',
                                                        )}
                                                    >
                                                        {strategy.ruleGroups.length}
                                                    </td>

                                                    <td
                                                        className={classNames(
                                                            strategyIdx === 0 ? '' : 'border-t border-transparent',
                                                            'relative py-3.5 pl-3 pr-4 text-right text-sm font-medium sm:pr-6',
                                                        )}
                                                    >
                                                        <div className="flex flex-row justify-end">
                                                            <button
                                                                type="button"
                                                                className="rounded bg-white/10 px-2 py-1 text-sm font-semibold text-white shadow-sm hover:bg-white/20 mx-2"
                                                                onClick={() => {
                                                                    setCurrentStrategy(strategy);
                                                                    setOpenEditStrategy(true);
                                                                }}
                                                            >
                                                                Edit
                                                            </button>
                                                            <button
                                                                type="button"
                                                                className="rounded bg-red-500 px-2 py-1 text-sm font-semibold text-white shadow-sm hover:bg-red-400 mx-2"
                                                                onClick={() => {
                                                                    setStrategyId(strategy.id);
                                                                    setOpenDeleteStrategy(true);
                                                                }}
                                                            >
                                                                Delete
                                                            </button>
                                                            <button
                                                                type="button"
                                                                onClick={() => {
                                                                    setCurrentStrategy(strategy);
                                                                    navigate(`/app/strategy/${strategy.id}/sessions`)
                                                                }}
                                                                className="inline-flex mx-2 items-center rounded-md bg-indigo-600 px-2.5 py-1.5 text-sm font-semibold text-gray-50 shadow-sm ring-1 ring-inset ring-indigo-400 hover:bg-indigo-500 disabled:cursor-not-allowed disabled:opacity-30 disabled:hover:bg-gray-800"
                                                            >
                                                                Details
                                                            </button>
                                                            {strategyIdx !== 0 ? <div className="absolute -top-px left-0 right-6 h-px bg-gray-600" /> : null}
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    )
}