import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil"
import { ruleGroupsState } from "../../../../../state/backtesting/atoms/rule_groups"
import { rulesState } from "../../../../../state/backtesting/atoms/rules";
import { backtestingManagerState } from "../../../../../state/backtesting/backtesting_manager_state";
import { useEffect, useState } from "react";
import { strategiesState } from "../../../../../state/backtesting/atoms/strategies";
import { Link, Outlet, useNavigate } from "react-router-dom";
import { currentStrategyState } from "../../../../../state/backtesting/atoms/current_strategy";
import {
    BriefcaseIcon,
    CalendarIcon,
    CheckIcon,
    ChevronDownIcon,
    ChevronRightIcon,
    CurrencyDollarIcon,
    LinkIcon,
    MapPinIcon,
    MinusSmallIcon,
    PencilIcon,
    PlusIcon,
    PlusSmallIcon,
} from '@heroicons/react/20/solid'
import { Disclosure, DisclosureButton, DisclosurePanel, Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react';
import CreateRuleGroupModal from "./components/CreateRuleGroupModal";
import CreateRuleModal from "./components/CreateRuleModal";
import EditRuleGroupModal from "./components/EditRuleGroupModal";
import EditRuleModal from "./components/EditRuleModal";
import EditStrategyModal from "../components/EditStrategyModal";
import DeleteStrategyModal from "../components/DeleteStrategyModal";
import DeleteRuleGroupModal from "./components/DeleteRuleGroupModal";
import DeleteRuleModal from "./components/DeleteRuleModal";
import { currentRuleGroupState } from "../../../../../state/backtesting/atoms/current_rule_group";
import { currentRuleState } from "../../../../../state/backtesting/atoms/current_rule";

function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(' ')
}

export default function StrategyDetails() {
    const setStrategies = useSetRecoilState(strategiesState);
    const backtestingManager = useRecoilValue(backtestingManagerState);
    const [currentStrategy, setCurrentStrategy] = useRecoilState(currentStrategyState);
    const [tabs, setTabs] = useState([
        { name: 'Statistics', href: 'statistics', current: window.location.pathname.split("/")[window.location.pathname.split("/").length - 1] == 'statistics' },
        { name: 'Sessions', href: 'sessions', current: window.location.pathname.split("/")[window.location.pathname.split("/").length - 1] == 'sessions' },
        { name: 'Rules', href: 'rules', current: window.location.pathname.split("/")[window.location.pathname.split("/").length - 1] == 'rules' },
        { name: 'Trades', href: 'trades', current: window.location.pathname.split("/")[window.location.pathname.split("/").length - 1] == 'trades' },
    ]);

    const navigate = useNavigate();

    const [deleteStrategyOpen, setOpenDeleteStrategy] = useState(false);
    const [editStrategyOpen, setOpenEditStrategy] = useState(false);

    useEffect(() => {
        if (currentStrategy == undefined) {
            const path = window.location.pathname.split("/");
            const strategyId = path[path.length - 2];

            backtestingManager.getStrategy(setStrategies, strategyId).then((strategy) => setCurrentStrategy(strategy));
        }
    }, [currentStrategy]);

    return (
        <main>
            <EditStrategyModal backtestingManager={backtestingManager} setStrategies={setStrategies} open={editStrategyOpen} setOpen={setOpenEditStrategy} />
            <DeleteStrategyModal backtestingManager={backtestingManager} setStrategies={setStrategies} open={deleteStrategyOpen} setOpen={setOpenDeleteStrategy} strategyId={currentStrategy?.id ?? ""} />


            <main>
                <div className="lg:flex lg:items-center lg:justify-between">
                    <div className="min-w-0 flex-1">
                        <button
                            type="button"
                            onClick={() => navigate("/app/strategies")}
                            className="rounded bg-white/10 px-2 py-1 text-xs font-semibold text-white shadow-sm hover:bg-white/20"
                        >
                            Back
                        </button>
                        <h2 className="mt-2 text-2xl font-bold leading-7 text-white sm:truncate sm:text-3xl sm:tracking-tight">
                            {currentStrategy?.name ?? ""}
                        </h2>
                        <div className="mt-1 flex flex-col sm:mt-0 sm:flex-row sm:flex-wrap sm:space-x-6">
                            <div className="mt-2 flex items-center text-sm text-gray-300">
                                <BriefcaseIcon aria-hidden="true" className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-500" />
                                Full-time
                            </div>
                            <div className="mt-2 flex items-center text-sm text-gray-300">
                                <MapPinIcon aria-hidden="true" className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-500" />
                                Remote
                            </div>
                            <div className="mt-2 flex items-center text-sm text-gray-300">
                                <CurrencyDollarIcon aria-hidden="true" className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-500" />
                                $120k – $140k
                            </div>
                            <div className="mt-2 flex items-center text-sm text-gray-300">
                                <CalendarIcon aria-hidden="true" className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-500" />
                                Closing on January 9, 2020
                            </div>
                        </div>
                    </div>
                    <div className="mt-5 flex lg:ml-4 lg:mt-0">
                        <span className="hidden sm:block">
                            <button
                                type="button"
                                className="inline-flex items-center rounded-md bg-white/10 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-white/20"
                                onClick={() => setOpenEditStrategy(true)}
                            >
                                <PencilIcon aria-hidden="true" className="-ml-0.5 mr-1.5 h-5 w-5" />
                                Edit Strategy
                            </button>
                        </span>
                        <span className="ml-3 hidden sm:block">
                            <button
                                type="button"
                                className="inline-flex items-center rounded-md bg-red-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-400"
                                onClick={() => setOpenDeleteStrategy(true)}
                            >
                                <LinkIcon aria-hidden="true" className="-ml-0.5 mr-1.5 h-5 w-5" />
                                Delete Strategy
                            </button>
                        </span>

                        {/* Dropdown */}
                        <Menu as="div" className="relative ml-3 sm:hidden">
                            <MenuButton className="inline-flex items-center rounded-md bg-white/10 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-white/20">
                                More
                                <ChevronDownIcon aria-hidden="true" className="-mr-1 ml-1.5 h-5 w-5" />
                            </MenuButton>
                            <MenuItems
                                transition
                                className="absolute left-0 z-10 -ml-1 mt-2 w-48 origin-top-left rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-200 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
                            >
                                <MenuItem>
                                    <a href="#" className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100">
                                        Edit
                                    </a>
                                </MenuItem>
                                <MenuItem>
                                    <a href="#" className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100">
                                        View
                                    </a>
                                </MenuItem>
                            </MenuItems>
                        </Menu>
                    </div>

                </div>
                <div className="border-b border-gray-600 pb-5 sm:pb-0 mt-10">
                    <div className="mt-3 sm:mt-4">
                        <div className="sm:hidden">
                            <label htmlFor="current-tab" className="sr-only">
                                Select a tab
                            </label>
                            <select
                                id="current-tab"
                                name="current-tab"
                                defaultValue={tabs.find((tab) => tab.current)?.name ?? "no name"}
                                className="block w-full rounded-md border-gray-600 py-2 pl-3 pr-10 text-base focus:border-indigo-400 focus:outline-none focus:ring-indigo-400 sm:text-sm"
                            >
                                {tabs.map((tab) => (
                                    <option key={tab.name}>{tab.name}</option>
                                ))}
                            </select>
                        </div>
                        <div className="hidden sm:block sm:ml-4">
                            <nav className="-mb-px flex space-x-8">
                                {tabs.map((tab) => (
                                    <Link
                                        key={tab.name}
                                        to={`${window.location.pathname.split("/").slice(0, window.location.pathname.split("/").length - 1).join("/")}/${tab.href}`}
                                        aria-current={tab.current ? 'page' : undefined}
                                        onClick={() => {
                                            setTabs(tabs.map((t) => {
                                                if (t.name == tab.name) {
                                                    t.current = true;
                                                } else {
                                                    t.current = false;
                                                }

                                                return t;
                                            }))
                                        }}
                                        className={classNames(
                                            tab.current
                                                ? 'border-indigo-300 text-indigo-300'
                                                : 'border-transparent text-gray-200 hover:border-gray-600 hover:text-gray-50',
                                            'whitespace-nowrap border-b-2 px-1 pb-4 text-sm font-medium',
                                        )}
                                    >
                                        {tab.name}
                                    </Link>
                                ))}
                            </nav>
                        </div>
                    </div>
                </div>
                <div>
                    <div className="bg-gray-900">
                        <div className="mx-auto px-6">
                            <Outlet />
                        </div>
                    </div>
                </div>
            </main>
        </main>
    )
}