import { useEffect, useState } from "react";
import { Link, useLoaderData, useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { authenticationManagerState } from "../../state/authentication/authentication_manager_state";
import { useFormik } from "formik";
import LandingPageHeader from "../landing/components/LandingPageHeader";

function RecoverPassword() {
    const navigate = useNavigate();
    const loaderData = useLoaderData();
    const authenticationManager = useRecoilValue(authenticationManagerState);
    const [showpassword, setShowPassword] = useState(false);
    const [passwordRecovered, setPasswordRecovered] = useState(false);

    useEffect(() => {
        console.log(loaderData);
    }, []);

    const validate = (values: { password?: string, confirmPassword?: string, api?: string }) => {
        const errors: { password?: string, confirmPassword?: string, api?: string } = {};
        if (!values.password) {
            errors.password = "You have to enter a password";
        }

        if (!values.confirmPassword) {
            errors.confirmPassword = "You have to confirm your password";
        }

        if (values.password !== values.confirmPassword) {
            errors.confirmPassword = "passwords are not matching"
        }

        return errors;
    }

    const {
        handleSubmit,
        handleChange,
        handleBlur,
        touched,
        values,
        errors,
    } = useFormik({
        initialValues: {
            password: "",
            confirmPassword: "",
        },
        validate,
        onSubmit: async (values) => {
            try {
                const urlParams = new URLSearchParams(window.location.search);
                const recoverToken = urlParams.get('recoverToken');
                const userId = urlParams.get('userId');

                await authenticationManager.recoverPassword(userId!, recoverToken!, values.confirmPassword!);
                setPasswordRecovered(true);
            } catch (error) {
                errors.api = "recover failed"
                return false;
            }

            return true;
        },
    });

    return (
        <>
            <div>
                <LandingPageHeader />
                <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
                    <div className="sm:mx-auto sm:w-full sm:max-w-sm">
                        <img
                            alt="Your Company"
                            src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=500"
                            className="mx-auto h-10 w-auto"
                        />
                        <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-white">
                            Recover Your Password
                        </h2>
                    </div>

                    <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
                        <form onSubmit={handleSubmit} className="space-y-6">
                            <div>
                                <label htmlFor="password" className="block text-sm font-medium leading-6 text-white">
                                    New Password
                                </label>
                                <div className="mt-2">
                                    <input
                                        id="password"
                                        name="password"
                                        type="password"
                                        required
                                        autoComplete="password"
                                        className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />
                                </div>
                                {touched.password && errors.password
                                    ? <p className="text-white">{errors.password}</p>
                                    : null}
                                <br />
                                {errors.api
                                    ? <p className="text-white">{errors.api}</p>
                                    : null}
                            </div>
                            <div>
                                <label htmlFor="confirm_password" className="block text-sm font-medium leading-6 text-white">
                                    Confirm Password
                                </label>
                                <div className="mt-2">
                                    <input
                                        id="confirm_password"
                                        name="confirm_password"
                                        type="confirm_password"
                                        required
                                        autoComplete="password"
                                        className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />
                                </div>
                                {touched.confirmPassword && errors.confirmPassword
                                    ? <p className="text-white">{errors.confirmPassword}</p>
                                    : null}
                                <br />
                                {errors.api
                                    ? <p className="text-white">{errors.api}</p>
                                    : null}
                                {passwordRecovered
                                    ? <p className="text-white">Password Recovered Successfully</p>
                                    : null}
                            </div>

                            <div>
                                <button
                                    type="submit"
                                    className="flex w-full justify-center rounded-md bg-indigo-500 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
                                >
                                    Update Password
                                </button>
                            </div>
                        </form>

                        <p className="mt-10 text-center text-sm text-gray-400">
                            <Link to="/login" className="font-semibold leading-6 text-indigo-400 hover:text-indigo-300">
                                Back to Log In
                            </Link>
                        </p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default RecoverPassword;