import { useRecoilState, useRecoilValue } from "recoil";
import { ordersState } from "../../../../../state/backtesting/atoms/orders";
import { lastBarState } from "../../../../../state/replay/atoms/lastBar";
import { Order } from "../../../../../state/backtesting/models/backtesting_models";

function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(' ')
}

export default function PassedOrders() {
    const [orders, setOrders] = useRecoilState(ordersState);
    const lastBar = useRecoilValue(lastBarState);

    const computeTradeAvgEntryPrice = (or: Order[]) => {
        const tradeOrders = or.filter((o) =>
            o.side.includes("buy") &&
            o.state == "passed"
        );

        let amountCount = 0;
        let priceCount = 0;

        for (let i = 0; i < tradeOrders.length; i++) {
            const order = tradeOrders[i];

            amountCount += order.amount;
            priceCount += order.price * order.amount
        }

        return (priceCount / amountCount);
    }

    const computeTradeRealisedPl = (tradeId: string, order: Order) => {
        if (order.side.includes("buy")) {
            return 0;
        }

        const amountSold = order.amount;
        const avgEntry = computeTradeAvgEntryPrice(orders.filter((o) => o.tradeId == order.tradeId));
        const exitPrice = order.price;

        return ((exitPrice - avgEntry) * amountSold);
    }

    return (
        <div className="bg-gray-950 h-full">
            <div>
                <div className="bg-gray-950">
                    <div className="overflow-x-auto">
                        <div className="inline-block min-w-full ">
                            <table className="min-w-full divide-y divide-gray-700">
                                <thead>
                                    <tr>
                                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-white">
                                            Side
                                        </th>
                                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-white">
                                            State
                                        </th>
                                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-white">
                                            Type
                                        </th>
                                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-white">
                                            Amount
                                        </th>
                                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-white">
                                            Price
                                        </th>
                                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-white">
                                            Realised P&L
                                        </th>
                                        <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-white sm:pl-0">
                                            Creation
                                        </th>
                                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-white">
                                            Execution
                                        </th>
                                        <th scope="col" className="py-3.5 pl-3">
                                            <span className="sr-only">Details</span>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className="divide-y divide-gray-800">
                                    {orders.filter((order) => order.state == "passed").map((order) => (
                                        <tr key={order.id}>

                                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-300">{order.side}</td>
                                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-300">{order.state}</td>
                                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-300">
                                                {order.orderType}
                                            </td>
                                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-300">
                                                {order.amount}
                                            </td>
                                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-300">
                                                {order.price}
                                            </td>
                                            <td className={classNames("whitespace-nowrap px-3 py-4 text-sm", computeTradeRealisedPl(order.tradeId, order) > 0 ? "text-green-400" : (computeTradeRealisedPl(order.tradeId, order) < 0 ? "text-red-500" : "text-gray-300"))}>
                                                {order.side.includes("sell") ? computeTradeRealisedPl(order.tradeId, order).toFixed(3) : "-"}
                                            </td>
                                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-300">
                                                {new Date(order.createdSessionTime * 1000).toUTCString()}
                                            </td>
                                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-300">
                                                {new Date(order.executionSessionTime * 1000).toUTCString()}
                                            </td>
                                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-300">
                                                <a href="#" className="text-indigo-400 hover:text-indigo-300">
                                                    Details<span className="sr-only">, {order.id}</span>
                                                </a>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}