import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil"
import { ruleGroupsState } from "../../../../../../state/backtesting/atoms/rule_groups"
import { rulesState } from "../../../../../../state/backtesting/atoms/rules";
import { backtestingManagerState } from "../../../../../../state/backtesting/backtesting_manager_state";
import { currentStrategyState } from "../../../../../../state/backtesting/atoms/current_strategy";
import {
    ChevronRightIcon,
    MinusSmallIcon,
    PlusIcon,
    PlusSmallIcon,
} from '@heroicons/react/20/solid'
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/react';
import CreateRuleGroupModal from "../components/CreateRuleGroupModal";
import CreateRuleModal from "../components/CreateRuleModal";
import EditRuleGroupModal from "../components/EditRuleGroupModal";
import EditRuleModal from "../components/EditRuleModal";
import DeleteRuleGroupModal from "../components/DeleteRuleGroupModal";
import DeleteRuleModal from "../components/DeleteRuleModal";
import { currentRuleGroupState } from "../../../../../../state/backtesting/atoms/current_rule_group";
import { currentRuleState } from "../../../../../../state/backtesting/atoms/current_rule";
import { useEffect, useState } from "react";
import { strategiesState } from "../../../../../../state/backtesting/atoms/strategies";

function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(' ')
}

export default function StrategyRules() {
    const setStrategies = useSetRecoilState(strategiesState);

    const [ruleGroups, setRuleGroups] = useRecoilState(ruleGroupsState);
    const [rules, setRules] = useRecoilState(rulesState);
    const backtestingManager = useRecoilValue(backtestingManagerState);

    const [currentRuleGroup, setCurrentRuleGroup] = useRecoilState(currentRuleGroupState);
    const [currentRule, setCurrentRule] = useRecoilState(currentRuleState);
    const [currentStrategy, setCurrentStrategy] = useRecoilState(currentStrategyState);

    const [ruleGroupId, setRuleGroupId] = useState("");
    const [createRuleGroupOpen, setOpenCreateRuleGroup] = useState(false);
    const [deleteRuleGroupOpen, setOpenDeleteRuleGroup] = useState(false);
    const [editRuleGroupOpen, setOpenEditRuleGroup] = useState(false);

    const [ruleId, setRuleId] = useState("");
    const [createRuleOpen, setOpenCreateRule] = useState(false);
    const [deleteRuleOpen, setOpenDeleteRule] = useState(false);
    const [editRuleOpen, setOpenEditRule] = useState(false);

    useEffect(() => {
        if (currentStrategy != undefined && ruleGroups.length == 0) {
            backtestingManager.listRuleGroups(setRuleGroups, currentStrategy.id);
        }
    }, [currentStrategy])

    useEffect(() => {
        if (rules.length == 0) {
            ruleGroups.forEach((group) => backtestingManager.listRules(setRules, group.id));
        }
    }, [ruleGroups]);

    return (
        <>
            <CreateRuleGroupModal backtestingManager={backtestingManager} setRuleGroups={setRuleGroups} open={createRuleGroupOpen} setOpen={setOpenCreateRuleGroup} />
            <CreateRuleModal backtestingManager={backtestingManager} setRules={setRules} open={createRuleOpen} setOpen={setOpenCreateRule} groupId={ruleGroupId} />
            <EditRuleGroupModal backtestingManager={backtestingManager} setRuleGroups={setRuleGroups} open={editRuleGroupOpen} setOpen={setOpenEditRuleGroup} />
            <EditRuleModal backtestingManager={backtestingManager} setRules={setRules} open={editRuleOpen} setOpen={setOpenEditRule} />
            <DeleteRuleGroupModal backtestingManager={backtestingManager} setRuleGroups={setRuleGroups} open={deleteRuleGroupOpen} setOpen={setOpenDeleteRuleGroup} groupId={ruleGroupId} />
            <DeleteRuleModal backtestingManager={backtestingManager} setRules={setRules} open={deleteRuleOpen} setOpen={setOpenDeleteRule} ruleId={ruleId} />
            <div className="mx-auto divide-y divide-white/10">
                <dl className="mt-10 space-y-6 divide-y divide-white/10">
                    {ruleGroups.filter((g) => g.strategyId === currentStrategy?.id ?? "").length ?? 0 > 0
                        ? ruleGroups.filter((g) => g.strategyId === currentStrategy?.id ?? "").map((g) =>
                            <Disclosure key={g.id} as="div" className="pt-6">
                                <dt>
                                    <DisclosureButton className="group flex w-full space-x-8 items-baseline justify-between text-left text-white">
                                        <div className="flex flex-1 space-x-10">
                                            <span className="text-xl font-semibold  basis-1/6 flex-1">{g.name}</span>
                                            <span className="text-base font-semibold  basis-5/6 flex-1">{g.description}</span>
                                        </div>
                                        <button
                                            type="button"
                                            className="inline-flex items-center rounded-md bg-white/10 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-white/20"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                e.stopPropagation();

                                                setCurrentRuleGroup(g)
                                                setOpenEditRuleGroup(true)
                                            }}
                                        >
                                            Edit Rule Group
                                        </button>
                                        <button
                                            type="button"
                                            className="inline-flex items-center rounded-md bg-red-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-400"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                e.stopPropagation();

                                                setRuleGroupId(g.id)
                                                setOpenDeleteRuleGroup(true)
                                            }}
                                        >
                                            Delete Rule Group
                                        </button>
                                        <button
                                            type="button"
                                            className="rounded-md bg-indigo-500 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                e.stopPropagation();

                                                setRuleGroupId(g.id);

                                                setOpenCreateRule(true)
                                            }}
                                        >
                                            Add Rule
                                        </button>
                                        <span className="ml-6 flex h-7 items-center">
                                            <PlusSmallIcon aria-hidden="true" className="h-6 w-6 group-data-[open]:hidden" />
                                            <MinusSmallIcon aria-hidden="true" className="h-6 w-6 [.group:not([data-open])_&]:hidden" />
                                        </span>
                                    </DisclosureButton>
                                </dt>
                                <DisclosurePanel as="dd" className="mt-6 pr-12">
                                    <ul role="list" className="divide-y divide-white/5">
                                        {rules.filter((rule) => rule.groupId == g.id).map((rule) => (
                                            <li key={rule.id} className="relative flex items-center space-x-4 py-4">
                                                <div className="min-w-0 flex-auto">
                                                    <div className="flex items-center gap-x-3">
                                                        <h2 className="min-w-0 text-sm font-semibold leading-6 text-white">
                                                            <a href="" className="flex gap-x-2">
                                                                <span className="truncate">{rule.name}</span>
                                                            </a>
                                                        </h2>
                                                    </div>
                                                    <div className="mt-3 flex items-center gap-x-2.5 text-xs leading-5 text-gray-400">
                                                        <p className="truncate">{rule.description}</p>
                                                        <svg viewBox="0 0 2 2" className="h-0.5 w-0.5 flex-none fill-gray-300">
                                                            <circle r={1} cx={1} cy={1} />
                                                        </svg>
                                                        <p className="whitespace-nowrap">{rule.created}</p>
                                                    </div>
                                                </div>
                                                <button
                                                    type="button"
                                                    className="rounded bg-white/10 px-2 py-1 text-xs font-semibold text-white shadow-sm hover:bg-white/20"
                                                    onClick={() => {
                                                        setRuleId(rule.id);
                                                        setCurrentRule(rule);

                                                        setOpenEditRule(true);
                                                    }}
                                                >
                                                    Edit Rule
                                                </button>
                                                <button
                                                    type="button"
                                                    className="rounded bg-red-500 px-2 py-1 text-xs font-semibold text-white shadow-sm hover:bg-red-400"
                                                    onClick={() => {
                                                        setRuleId(rule.id);
                                                        setRuleGroupId(g.id);

                                                        setOpenDeleteRule(true)
                                                    }}
                                                >
                                                    Delete Rule
                                                </button>
                                                <div
                                                    className={classNames(
                                                        'text-indigo-400 bg-indigo-400/10 ring-indigo-400/30',
                                                        'flex-none rounded-full px-2 py-1 text-xs font-medium ring-1 ring-inset',
                                                    )}
                                                >
                                                    active
                                                </div>
                                                <ChevronRightIcon aria-hidden="true" className="h-5 w-5 flex-none text-gray-400" />
                                            </li>
                                        ))}
                                    </ul>
                                </DisclosurePanel>
                            </Disclosure>
                        )
                        : <div className='text-gray-50 pb-8 pt-48'>
                            <div className="text-center">
                                <svg
                                    fill="none"
                                    stroke="currentColor"
                                    viewBox="0 0 24 24"
                                    aria-hidden="true"
                                    className="mx-auto h-12 w-12 text-gray-200"
                                >
                                    <path
                                        d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
                                        strokeWidth={2}
                                        vectorEffect="non-scaling-stroke"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                </svg>
                                <h3 className="mt-2 text-sm font-semibold text-gray-50">No Rules</h3>
                                <p className="mt-1 text-sm text-gray-100">Get started by creating a rule group</p>
                                <div className="mt-6">
                                    <button
                                        type="button"
                                        onClick={() => setOpenCreateRuleGroup(true)}
                                        className="inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                    >
                                        <PlusIcon aria-hidden="true" className="-ml-0.5 mr-1.5 h-5 w-5" />
                                        New Rule Group
                                    </button>
                                </div>
                            </div>
                        </div>
                    }
                </dl>
            </div>
        </>
    )
}